import {
    MSGS_NOTIFICACIONES_UPLOAD_FILE,
    ERRORS_DT_MANUALS,
    ERRORS_SELL_IN,
    ERRORS_MASTER_PRODUCTS,
    ERRORS_MASTER_CLIENTS,
    ERRORS_MASTER_PRICE,
} from '../../../Constans/UploadFile/UploadFile'

const INIT_STATE = {
    rex_msgs_notificaciones : [],
    rex_errors_dt_manuals : [],
    rex_errors_sell_in : [],
    rex_errors_master_products : [],
    rex_errors_master_clients : [],
    rex_errors_master_price : [],
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MSGS_NOTIFICACIONES_UPLOAD_FILE: {
            return {
                ...state,
                rex_msgs_notificaciones : action.payload
            }
        }
        case ERRORS_DT_MANUALS: {
            return {
                ...state,
                rex_errors_dt_manuals : action.payload
            }
        }
        case ERRORS_SELL_IN: {
            return {
                ...state,
                rex_errors_sell_in : action.payload
            }
        }
        case ERRORS_MASTER_PRODUCTS: {
            return {
                ...state,
                rex_errors_master_products : action.payload
            }
        }
        case ERRORS_MASTER_CLIENTS: {
            return {
                ...state,
                rex_errors_master_clients : action.payload
            }
        }
        case ERRORS_MASTER_PRICE: {
            return {
                ...state,
                rex_errors_master_price : action.payload
            }
        }
        default:
            return state;
    }
}
