import config from '../../../../config'
import { notification } from 'antd'
import { 
    CARGANDO_DATA_CARGA_ARCHIVOS,
    FILTRO_TIPO_ARCHIVOS_CARGA_ARCHIVOS,
    MOSTRAR_SOLO_ARCHIVOS_CARGADOS_CARGA_ARCHIVOS,
    MOSTRAR_SOLO_ARCHIVOS_PROCESADOS_CARGA_ARCHIVOS,
    OBTENER_DATA_CARGA_ARCHIVOS,
    OBTENER_DATA_CARGA_ARCHIVOS_COPIA,
    OBTENER_DATA_TIPOS_ARCHIVOS_CARGA_ARCHIVOS
} from '../../../../Constans/Administracion/Administrador/CargaArchivos'


const notificacionServidor = (type, mensaje, title=null) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: title?title:'Error',
            description: <div dangerouslySetInnerHTML={{ __html: mensaje }} />,
            duration: 10
        })
    }
}


export const ObtenerDataCargaArchivosReducer = () => async ( dispatch ) => {

    dispatch({
        type    : CARGANDO_DATA_CARGA_ARCHIVOS,
        payload : true
    })

    await fetch(config.api_url+'administration/get-upload-files',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then( async res => {return res.json()})
    .then(data => {           
        if(data.response){

            const data_carga_archivos = data.data
            dispatch({
                type: OBTENER_DATA_CARGA_ARCHIVOS,
                payload: data_carga_archivos
            })

            dispatch({
                type: OBTENER_DATA_CARGA_ARCHIVOS_COPIA,
                payload: data_carga_archivos
            })
            // dispatch({
            //     type: OBTENER_DATA_CARGA_ARCHIVOS_COPIA,
            //     payload: data_carga_archivos.filter(dat => dat.carexito)
            // })

            const obtener_tipos_archivos = data_carga_archivos.map(car => car.cartipo != '' ? car.cartipo : 'No asignado')
            const tipos_archivos = [...new Set(obtener_tipos_archivos)]

            dispatch({
                type : OBTENER_DATA_TIPOS_ARCHIVOS_CARGA_ARCHIVOS,
                payload : tipos_archivos
            })

        }
    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type    : CARGANDO_DATA_CARGA_ARCHIVOS,
        payload : false
    })
}

export const DescargarArchivoCargaArchivosReducer = (url) => async ( dispatch ) => {

    await fetch(config.api_url+'carga-archivos/generar-descarga?token='+url,
        {
            mode:'cors',
            method: 'GET',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then( async res => {return res.json()})
    .then(data => {
        // console.log(data)

    }).catch((error)=> {
        console.log(error)
    })
}

export const MostrarSoloArchivosProcesadosCargaArchivosReducer = ( value ) => async ( dispatch, getState ) => {

    const data_carga_archivos       = getState().cargaArchivos.rex_data_carga_archivos

    dispatch({
        type: MOSTRAR_SOLO_ARCHIVOS_PROCESADOS_CARGA_ARCHIVOS,
        payload: value
    })

    if(!value){
        dispatch({
            type    : OBTENER_DATA_CARGA_ARCHIVOS_COPIA,
            payload : data_carga_archivos
        })

    }else{

        const data_carga_archivo_filtro = data_carga_archivos.filter( car => car.carfechaprocesado != null )
        dispatch({
            type    : OBTENER_DATA_CARGA_ARCHIVOS_COPIA,
            payload : data_carga_archivo_filtro
        })
    }

}

// export const MostrarArchivosCargadosCargaArchivosReducer = (value) => async ( dispatch, getState ) => {

//     const data_carga_archivos       = getState().cargaArchivos.rex_data_carga_archivos

//     dispatch({
//         type: MOSTRAR_SOLO_ARCHIVOS_CARGADOS_CARGA_ARCHIVOS,
//         payload: value
//     })

//     if(!value){
//         dispatch({
//             type    : OBTENER_DATA_CARGA_ARCHIVOS_COPIA,
//             payload : data_carga_archivos
//         })

//     }else{

//         const data_carga_archivo_filtro = data_carga_archivos.filter( car => car.carexito )
//         dispatch({
//             type    : OBTENER_DATA_CARGA_ARCHIVOS_COPIA,
//             payload : data_carga_archivo_filtro
//         })
//     }    
// }

export const FiltrarTiposArchivoCargaArchivosReducer = (cartipo_value, carprocess_value, actionText, value) => async ( dispatch, getState ) => {

    const data_carga_archivos = getState().cargaArchivos.rex_data_carga_archivos
    const mostrar_archivos_cargados = getState().cargaArchivos.rex_mostrar_solo_archivos_cargados_carga_archivos

    if(actionText){

        cartipo_value = cartipo_value == undefined ? '' : cartipo_value

        let data_filter = data_carga_archivos.filter(dat => 
                                                (value ? dat.carexito : true)
                                                && dat.cartipo.includes(cartipo_value)
                                                && (carprocess_value === '' 
                                                ? true : carprocess_value == 'Procesados' ? dat.carfechaprocesado 
                                                    : !dat.carfechaprocesado)
                                            )

        data_filter.map((dat, index) => {dat['index']= index+1} )
        dispatch({
            type: OBTENER_DATA_CARGA_ARCHIVOS_COPIA,
            payload: data_filter
        })

        dispatch({
            type: MOSTRAR_SOLO_ARCHIVOS_CARGADOS_CARGA_ARCHIVOS,
            payload: !mostrar_archivos_cargados
        })
    }else{
        
        if((cartipo_value == '' && carprocess_value == '') || (cartipo_value == undefined && carprocess_value == '')){
            dispatch({
                type    : OBTENER_DATA_CARGA_ARCHIVOS_COPIA,
                payload : data_carga_archivos
            })
    
        }else{
            cartipo_value = cartipo_value == undefined ? '' : cartipo_value    
            const data_filter = data_carga_archivos.filter(dat => 
                                                            (dat.cartipo.includes(cartipo_value))
                                                            && (carprocess_value === '' 
                                                                    ?   true 
                                                                    :   carprocess_value == 'Cargado, Procesado' 
                                                                        ?   dat.carfechaprocesado && dat.carexito
                                                                        :   carprocess_value == 'Cargado, No Procesado' 
                                                                            ?   dat.carfechaprocesado == null && dat.carexito
                                                                            :   carprocess_value == 'Cargado parcialmente, Procesado' 
                                                                                ?   dat.carfechaprocesado && !dat.carexito
                                                                                :   dat.carfechaprocesado == null && !dat.carexito
                                                                ))
            data_filter.map((dat, index) => {dat['index']= index+1} )
            dispatch({
                type    : OBTENER_DATA_CARGA_ARCHIVOS_COPIA,
                payload : data_filter
            })
        }

    }
}

export const EliminarArchivoCargaArchivosReducer = (id) => async ( dispatch ) => {

    await fetch(config.api_url+'administration/delete-upload-file',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_carid : id
            }),
        }
    )
    .then( async res => {return res.json()})
    .then(data => {
        if(data.response){
            dispatch(ObtenerDataCargaArchivosReducer())
            notificacionServidor('success', data.message)
        }else{
            notificacionServidor('error', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })
}

export const ProcesarArchivoCargaArchivosReducer = (id) => async ( dispatch ) => {
    // await fetch(config.api_url+'administration/process-file',
    //     {
    //         mode:'cors',
    //         method: 'POST',
    //         headers: {
    //             'Accept' : 'application/json',
    //             'Content-type' : 'application/json',
    //             'usu_token'	   : localStorage.getItem("usutoken"),
    //         },
    //         body: JSON.stringify({
    //             req_carid : id
    //         }),
    //     }
    // )
    // .then( async res => {return res.json()})
    // .then(data => {
    //     if(data.response){
    //         dispatch(ObtenerDataCargaArchivosReducer())
    //         notificacionServidor('success', data.message)
    //     }else{
    //         notificacionServidor('error', data.message)
    //     }
    // }).catch((error)=> {
    //     console.log(error)
    // })
}