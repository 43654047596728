import { Card, Row, Col, Typography } from 'antd'
import React,{useState} from 'react'
import {
    SettingOutlined,
    AlertOutlined,
    CaretLeftOutlined,
    CaretRightOutlined
} from '@ant-design/icons'
import '../../Styles/Routes/CargarData/CargarData.css'
import iconoCampana from '../../Assets/Img/Carga-Archivos/campana.svg'
import ModalManual from '../../Components/CargarData/ModalManual'
import ModalClientes from '../../Components/CargarData/ModalClientes'
import ModalProductos from '../../Components/CargarData/ModalProductos'
import ModalListaPrecios from '../../Components/CargarData/ModalListaPrecios'
import ModalSellin from '../../Components/CargarData/ModalSellin'
import ModalInventarios from '../../Components/CargarData/ModalInventarios'
import ModalPrecios from '../../Components/CargarData/ModalPrecios'

const CargarData = (props) => {
    
    const collapsed = props.collapsed
    const { Title } = Typography

    const [collapsedNoti, setCollapsedNoti] = useState(false);
    const notificaciones = []
    

    const [showModalManual, setShowModalManual] = useState(false)
    const [showModalCliente, setShowModalCliente] = useState(false)
    const [showModalProducto, setShowModalProducto] = useState(false)
    const [showModalListaPrecio, setModalListaPrecio] = useState(false)
    const [showModalSellin, setModalSellin] = useState(false)
    const [showModalInventarios, setModalInventarios] = useState(false)
    const [showModalPrecios, setModalPrecios] = useState(false)

    const expandCollapseNoti = () => {
        setCollapsedNoti(!collapsedNoti);
    };

    return (
        <div
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card 
                className="Card-Container-Cargar-Data Card-Container-CargarData"
                style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    width: '100%',
                    position:'relative'
                }}
            >
                <div
                    style={{
                        position:'absolute',
                        top:'95px',
                        left:'25px'
                    }}
                >
                    <Title level={4} style={{marginBottom: 0}}>
                        Procesos
                    </Title>
                </div>
                <Row
                    style={{
                        width :'100%',
                        marginBottom : '10px'
                        // marginLeft:'50px',
                        // marginRight:'50px'
                    }}
                    justify="center"
                >
                    <Col 
                        span={6}
                        style={{ 
                            textAlign: 'center',
                            paddingLeft:'15px',
                            paddingRight:'15px'
                        }} 
                    >
                        <Card
                            style={{
                                cursor:'pointer'
                            }}
                            onClick={() => {
                                setShowModalManual(!showModalManual)
                            }}
                        >
                            <div>
                                <SettingOutlined 
                                    style={{
                                        fontSize: '45px',
                                        color:'#25235F',
                                        marginBottom:'10px'
                                    }}
                                />
                                <div className='Txt-Btn-Cargar-Data'>
                                    Carga Manual FDH
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col 
                        span={6}
                        style={{ 
                            textAlign: 'center',
                            paddingLeft:'15px',
                            paddingRight:'15px'
                        }} 
                    >
                        <Card
                            style={{
                                cursor:'pointer'
                            }}
                            onClick={() => {
                                setShowModalProducto(!showModalProducto)
                            }}
                        >
                            <div>
                                <SettingOutlined 
                                    style={{
                                        fontSize: '45px',
                                        color:'#25235F',
                                        marginBottom:'10px'
                                    }}
                                />
                                <div className='Txt-Btn-Cargar-Data'>
                                    Carga Maestro Producto
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col 
                        span={6}
                        style={{ 
                            textAlign: 'center',
                            paddingLeft:'15px',
                            paddingRight:'15px',
                        }} 
                    >
                        <Card
                            style={{
                                cursor:'pointer'
                            }}
                            onClick={() => {
                                setShowModalCliente(!showModalCliente)
                            }}
                        >
                            <div>
                                <SettingOutlined 
                                    style={{
                                        fontSize: '45px',
                                        color:'#25235F',
                                        marginBottom:'10px'
                                    }}
                                />
                                <div className='Txt-Btn-Cargar-Data'>
                                    Carga Maestro Clientes
                                </div>
                            </div>
                        </Card>
                    </Col>
                
                    {/* <Col 
                        span={6}
                        style={{ 
                            textAlign: 'center',
                            paddingLeft:'15px',
                            paddingRight:'15px'
                        }} 
                    >
                        <Card
                            style={{
                                cursor:'pointer'
                            }}
                            onClick={() => {
                                setModalListaPrecio(!showModalListaPrecio)
                            }}
                        >
                            <div>
                                <SettingOutlined 
                                    style={{
                                        fontSize: '45px',
                                        color:'#25235F',
                                        marginBottom:'10px'
                                    }}
                                />
                                <div className='Txt-Btn-Cargar-Data'>
                                    Carga Lista Precios
                                </div>
                            </div>
                        </Card>
                    </Col> */}
                                        <Col 
                        span={6}
                        style={{ 
                            textAlign: 'center',
                            paddingLeft:'15px',
                            paddingRight:'15px'
                        }} 
                    >
                        <Card
                            style={{
                                cursor:'pointer'
                            }}
                            onClick={() => {
                                setModalSellin(!showModalSellin)
                            }}
                        >
                            <div>
                                <SettingOutlined 
                                    style={{
                                        fontSize: '45px',
                                        color:'#25235F',
                                        marginBottom:'10px'
                                    }}
                                />
                                <div className='Txt-Btn-Cargar-Data'>
                                    Carga Sell In
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row
                    style={{
                        width :'100%',
                        marginBottom : '10px'
                        // marginLeft:'50px',
                        // marginRight:'50px'
                    }}
                    justify="center"
                >

                    <Col 
                        span={6}
                        style={{ 
                            textAlign: 'center',
                            paddingLeft:'15px',
                            paddingRight:'15px',
                        }} 
                    >
                        <Card
                            style={{
                                cursor:'pointer'
                            }}
                            onClick={() => {
                                setModalInventarios(!showModalInventarios)
                            }}
                        >
                            <div>
                                <SettingOutlined 
                                    style={{
                                        fontSize: '45px',
                                        color:'#25235F',
                                        marginBottom:'10px'
                                    }}
                                />
                                <div className='Txt-Btn-Cargar-Data'>
                                    Carga Inventarios
                                </div>
                            </div>
                        </Card>
                    </Col>
                
                    <Col 
                        span={6}
                        style={{ 
                            textAlign: 'center',
                            paddingLeft:'15px',
                            paddingRight:'15px'
                        }} 
                    >
                        <Card
                            style={{
                                cursor:'pointer'
                            }}
                            onClick={() => {
                                setModalPrecios(!showModalPrecios)
                            }}
                        >
                            <div>
                                <SettingOutlined 
                                    style={{
                                        fontSize: '45px',
                                        color:'#25235F',
                                        marginBottom:'10px'
                                    }}
                                />
                                <div className='Txt-Btn-Cargar-Data'>
                                    Carga Maestro Precios
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Card>
            {/* <div 
                className={`Container-Notificacion-Cargar-Data ${collapsedNoti? 'Collapse-Noti-Expanded': ''}`}
            >
                <div className='Title-Notificacion-Cargar-Data'>
                    <Row>
                        <Col span={24} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <div className='Buton-Notification-Cargar-Data'>
                                {
                                    collapsedNoti
                                    ? <CaretRightOutlined 
                                        onClick={() => expandCollapseNoti()}
                                        />
                                    : <CaretLeftOutlined 
                                        onClick={() => expandCollapseNoti()}
                                    />
                                }
                            </div>

                            <div style={{display:'flex', justifyContent:'center'}}>
                                <AlertOutlined style={{margin:'0 8px', color:'#004fb8'}}/>
                                <span style={{fontSize:'15px', color:'#004fb8'}}>Notificaciones</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='Container-Body-Notificacion-Cargar-Data'>
                    {notificaciones.length > 0 
                    ? <div>Hay data</div>
                    : <div className='Container-Body-No-Data-Notificacion-Cargar-Data'>
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <img src={iconoCampana}/>
                            </div>
                            <span style={{color:'#acafb7'}}>No hay notificaciones recientes</span>
                        </div>
                    }
                </div>
            </div> */}
            <ModalPrecios
                isModalOpen={showModalPrecios}
                setIsModalOpen={setModalPrecios}
            />
            <ModalInventarios
                isModalOpen={showModalInventarios}
                setIsModalOpen={setModalInventarios}
            />
            <ModalSellin
                isModalOpen={showModalSellin}
                setIsModalOpen={setModalSellin}
            />
            <ModalManual 
                isModalOpen={showModalManual}
                setIsModalOpen={setShowModalManual}
            />

            <ModalClientes 
                isModalOpen={showModalCliente}
                setIsModalOpen={setShowModalCliente}
            />

            <ModalProductos 
                isModalOpen={showModalProducto}
                setIsModalOpen={setShowModalProducto}
            />

            <ModalListaPrecios
                isModalOpen={showModalListaPrecio}
                setIsModalOpen={setModalListaPrecio}
            />
        </div>
    )
}

export default CargarData