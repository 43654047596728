import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ChangeDataControlDistributorsReducer, GetDataControlDistributorsReducer } from '../../../Redux/Actions/Administracion/Distribuidoras/Distribuidoras'
import { Typography, Card, Button, Table, Switch, Tooltip, Input, Pagination } from 'antd'
import {
    ArrowLeftOutlined,
    ReloadOutlined,
    FilterOutlined,
} from '@ant-design/icons'

const ControlDistribuidors = (props) => {
    const collapsed = props.collapsed

    const [pageDistributors, setPageDistributors] = useState(1)

    const { Title } = Typography
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {
        rex_data_control_distributors,
    } = useSelector(({distribuidoras}) => distribuidoras)

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined />),
        onFilter: (value, record) => record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
    })

    const columns = [
        {
            title: 'N°',
            dataIndex: 'index',
            sorter: (a, b) => a.index ? a.index - b.index : false,
            showSorterTooltip: false,
            render: (_, record) => <div>{record.index}</div>,
            align:'center'
        },
        {
            title: 'Distribuidora',
            dataIndex: 'cod_ship_to',
            ...getColumnSearchProps('cod_ship_to', 'codigo distribuidor'),
            sorter: (a, b) => a.cod_ship_to? a.cod_ship_to.localeCompare(b.cod_ship_to) : false,
            showSorterTooltip: false,
            render: (cod_ship_to) => <div title={cod_ship_to}>{cod_ship_to}</div>,
            align:'center'
        },
        {
            title: 'Cliente Hml',
            dataIndex: 'cliente_hml',
            ...getColumnSearchProps('cliente_hml', 'cliente hml'),
            sorter: (a, b) => a.cliente_hml? a.cliente_hml.localeCompare(b.cliente_hml) : false,
            showSorterTooltip: false,
            render: (cliente_hml) => <div style={{textAlign: 'end'}} title={cliente_hml}>{cliente_hml}</div>,
            align:'center'
        },
        {
            title: 'Sucursal Hml',
            dataIndex: 'client_sub_hml',
            ...getColumnSearchProps('client_sub_hml', 'sucusrsal hml'),
            sorter: (a, b) => a.client_sub_hml? a.client_sub_hml.localeCompare(b.client_sub_hml) : false,
            showSorterTooltip: false,
            render: (client_sub_hml) => <div style={{textAlign: 'end'}} title={client_sub_hml}>{client_sub_hml}</div>,
            align:'center'
        },
        {
            title: 'Acciones',
            dataIndex: '',
            render: (_, record) => <div>
                <Switch 
                    checkedChildren="Automático" 
                    unCheckedChildren="Manual" 
                    size="small"
                    onChange={(e) => changeDataControlDistributors(record.id, e)}
                    checked={record.type_client ? true : false}
                />
            </div>,
            align:'center'
        }
    ]

    const getControlDistributors = () => {
        dispatch(GetDataControlDistributorsReducer())
    }

    const changeDataControlDistributors = (id, state) => {
        dispatch(ChangeDataControlDistributorsReducer(id, state))
    }

    useEffect(() => {
        getControlDistributors()
    }, [])

    return (
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Button type='primary' size='small' ghost onClick={() => navigate(-1)} style={{marginRight:'10px'}}>
                                <ArrowLeftOutlined />
                            </Button>
                            <Title className="Title-Module">Control Distribuidoras</Title>
                        </div>
                    }
                    className="Card-Item"
                >
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Button
                            className='Button-Extra-Option-Type-User' size='small'
                            onClick={()=> getControlDistributors()} 
                        >
                            <ReloadOutlined
                                className='Icon-Button-Extra-Option-Type-User'
                            />Recargar
                        </Button>
                        <div style={{display:'flex', columnGap: '10px', alignItems: 'center'}}>
                            <Pagination
                                current={pageDistributors}
                                total={rex_data_control_distributors.length}
                                pageSize={15}
                                onChange={async (page, pageSize) => setPageDistributors(page)}
                                size='small'
                            />
                            <div>Total: {Intl.NumberFormat('en-IN').format(rex_data_control_distributors.length)} registros</div> 
                        </div>
                    </div>
                    <Table
                        pagination={{
                            position: ["none", "none"],
                            pageSize: 15,
                            current: pageDistributors,
                        }}
                        columns={columns}
                        dataSource={rex_data_control_distributors}
                        className="Table-Home"
                        size='small'
                    />
                </Card>
            </Card>
        </div>
    )
}

export default ControlDistribuidors