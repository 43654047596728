import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card } from 'antd'
import campana from '../../Assets/Img/UploadFile/campana.svg'
import flechaIzquierda from '../../Assets/Img/UploadFile/flechaIzquierdaNaranja.png'
import campanaNotificacion from '../../Assets/Img/UploadFile/campanaNotificacion.png'
import '../../Styles/Components/UploadFile/CardNotification.css'
import {
    DeleteOutlined,
    PlusCircleOutlined
} from '@ant-design/icons';

const CardNotification = () => {
    const [activeNotification, setActiveNotification] = useState(false)

    const {
        rex_msgs_notificaciones,
        rex_errors_dt_manuals,
        rex_errors_sell_in,
        rex_errors_master_products,
        rex_errors_master_clients,
        rex_errors_master_price
    } = useSelector(({uploadFile}) => uploadFile)
    
    return(
        <Card 
            title={
                <div className="Container-Title">
                    <img 
                        onClick={() => setActiveNotification(!activeNotification)}
                        src={flechaIzquierda} 
                        className="Icon-Arrow-Notification"
                    />
                    <img src={campanaNotificacion} className="Icon-Bell-Notification" />
                    <div className="Text-Title">Notificaciones</div>
                </div>
            }
            className={`Card-Notification ${activeNotification ? 'Active-Notification' : ''}`}
        >
            {
                rex_errors_dt_manuals.length > 0 &&
                <>
                <p className="Title-Errors">Errores DT Manuales</p>
                {rex_errors_dt_manuals.map((dt_m, index) => 
                    <div className="Text-Errors" key={index}>- {dt_m}</div>
                )}
                </>
            }
            {
                rex_errors_sell_in.length > 0 &&
                <>
                <div className="Error-separate"></div>
                <p className="Title-Errors">Errores Sell In</p>
                {rex_errors_sell_in.map((si, index) => 
                    <div className="Text-Errors" key={index}>- {si}</div>
                )}
                </>
            }
            {
                rex_errors_master_products.length > 0 &&
                <>
                <div className="Error-separate"></div>
                <p className="Title-Errors">Errores Master de Producto</p>
                {rex_errors_master_products.map((mp, index) => 
                    <div className="Text-Errors" key={index}>- {mp}</div>
                )}
                </>
            }
            {
                rex_errors_master_clients.length > 0 &&
                <>
                <div className="Error-separate"></div>
                <p className="Title-Errors">Errores Master de Clientes</p>
                {rex_errors_master_clients.map((mc, index) => 
                    <div className="Text-Errors" key={index}>- {mc}</div>
                )}
                </>
            }
            {
                rex_errors_master_price.length > 0 &&
                <>
                <div className="Error-separate"></div>
                <p className="Title-Errors">Errores Master de Precios</p>
                {rex_errors_master_price.map((mp, index) => 
                    <div className="Text-Errors" key={index}>- {mp}</div>
                )}
                </>
            }

            {
                rex_msgs_notificaciones.length > 0 &&
                <>
                    {
                        rex_msgs_notificaciones.map((mp, index) => {
                            return(
                                <div
                                    className='Container-Notificacion-Upload'
                                >
                                    {
                                        index == 0
                                        ?<></>
                                        :<div className="Error-separate"></div>
                                    }
                                    <div 
                                        className={
                                            mp.respuesta == true
                                            ?"Title-Correcto-Upload"
                                            :"Title-Errors"
                                        }
                                    >
                                        {mp.titulo}
                                    </div>
                                    {
                                        activeNotification == true
                                        ?null
                                        :<div 
                                            key={index}
                                            className={
                                                mp.respuesta == true
                                                ? "Text-Correcto-Upload"
                                                : "Text-Errors"
                                            }
                                        >
                                            {mp.mensaje}
                                        </div>
                                    }
                                    <div
                                        className='Container-Iconos-Notificaciones-Upload'
                                    >
                                        <DeleteOutlined 
                                            className='Icon-Notificacion-Upload'
                                        />
                                    </div>
                                    <div
                                        className='Container-Iconos-Notificacion-Plus-Upload'
                                        onClick={() => {
                                            setActiveNotification(!activeNotification)
                                        }}
                                    >
                                        <PlusCircleOutlined 
                                            className='Icon-Plus-Notificacion-Upload'
                                        />
                                    </div>
                                    {
                                        activeNotification == true
                                        ?<div>
                                            {
                                                mp.data.length > 0 
                                                ? mp.data.map((notf, pos) => {
                                                    return(
                                                        <div
                                                            style={{
                                                                paddingLeft:'10px'
                                                            }}
                                                        >
                                                            <div
                                                                className='Titulo-Detalle-Notificacion-Upload'
                                                            >
                                                                {
                                                                    notf.columna ? 
                                                                    <>{pos+1}) Se encontraron las siguientes observaciones en la columna <b>{notf.columna}</b>:</>
                                                                    :'Se encontraron observaciones en el archivo'
                                                                }
                                                            </div>
                                                            {
                                                                notf.notificaciones.map((detalle, posdet) => {
                                                                    return (
                                                                        detalle.rows
                                                                        ?<>
                                                                        {
                                                                            mp.error_excel?.length > 0
                                                                            ? mp.error_excel.map(sheet => 
                                                                                <div
                                                                                    style={{
                                                                                        paddingLeft:'10px'
                                                                                    }}
                                                                                >
                                                                                    <div className='Titulo-Detalle-Notificacion-Upload'>
                                                                                        - {sheet}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                            : null
                                                                        }
                                                                        <div className='Detalle-Notificacion-Upload'>
                                                                            <div
                                                                                style={{
                                                                                    paddingLeft:'20px'
                                                                                }}
                                                                            >
                                                                            {
                                                                                "- "+detalle.msg
                                                                            }, en las siguientes lineas: {
                                                                                detalle.rows.map((row, pos) => {
                                                                                    return (
                                                                                        <b>
                                                                                            {
                                                                                                pos >= 6
                                                                                                ?pos == 6
                                                                                                    ?<span>etc</span>
                                                                                                    :null
                                                                                                :<span>{row}, </span>
                                                                                            }
                                                                                        </b>
                                                                                    )
                                                                                })
                                                                            }
                                                                            </div>
                                                                        </div>
                                                                        </>
                                                                        :<>
                                                                        <div className='Detalle-Notificacion-Upload'>{detalle.msg}</div>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                })

                                                : <div
                                                    style={{
                                                        paddingLeft:'10px'
                                                    }}
                                                    >
                                                        <div style={{fontSize:'12px'}} className={`${!mp.respuesta && 'Titulo-Detalle-Notificacion-Upload'}`}>{mp.mensaje}</div>
                                                        {/* {
                                                            mp.error_excel.length > 0
                                                            ? mp.error_excel.map(sheet => 
                                                                <div className='Titulo-Detalle-Notificacion-Upload'>
                                                                    - {sheet}
                                                                </div>
                                                            )
                                                            : null
                                                        } */}
                                                    </div>
                                            }
                                        </div>
                                        :null
                                    }
                                </div>
                            )
                        })
                    }
                </>
            }

            {
                rex_msgs_notificaciones.length == 0 && rex_errors_dt_manuals.length == 0 && rex_errors_sell_in.length == 0 &&  rex_errors_master_products.length == 0 && rex_errors_master_clients.length == 0 && rex_errors_master_price.length == 0  ?
                <div className="Container-Not-Notification">
                    <img src={campana} className="Image-Not-Notification" />
                    <div className="Text-Not-Notification">No hay notificaciones recientes</div>
                </div>
                : null
            }
        </Card>
    )
}

export default CardNotification