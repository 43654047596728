import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { GetDataMasterPricesReducer, PaginateMasterPricesReducer, FilterMasterPricesReducer, OrderMasterPricesReducer, ClearMasterPricesReducer } from '../../../Redux/Actions/Administracion/MasterPrices/MasterPrices'
import { Card, Typography, Table, Button, Input, DatePicker, Pagination, Skeleton } from 'antd'
import {
    FilterOutlined,
    ReloadOutlined,
    CalendarOutlined,
    CaretUpOutlined,
    CaretDownOutlined,
} from '@ant-design/icons'

const MaestraPrices = (props) => {
    const collapsed = props.collapsed
    const { Title } = Typography

    const [filterSection, setFilterSection] = useState(false)
    const dispatch = useDispatch()
    const [datePicker, setDatePicker] = useState()

    useEffect(() => {
        dispatch(GetDataMasterPricesReducer())
    }, [])

    const {
        rex_data_master_prices,
        rex_filter_data_master_prices,
        rex_order_data_master_prices,
        rex_paginate_master_prices,
        rex_register_total_master_prices,
        rex_loading_data_master_prices,
    } = useSelector(({masterPrices}) => masterPrices)

    const getColumnSearchProps = (dataIndex, texto, valueText) => ({
        filterDropdown: () => (
            <div
                style={{padding: 8,}}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Buscar ${texto}`}
                    onChange={async (e) => {
                        setFilterSection(true)
                        await dispatch(FilterMasterPricesReducer(e.target.value, dataIndex))
                    }}
                    style={{display: 'block'}}
                    value={valueText}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined />),
        onFilter: (value, record) => {
            const valueData = record[dataIndex]
            return valueData ? valueData.toString().toLowerCase().includes(value.toLowerCase()) : false
        }
    })

    const getColumnDateProps = (dataIndex, nameColumn) => ({
        filterDropdown: () => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <DatePicker
                    picker="month"
                    onChange={(value, date)=> {
                        setDatePicker(value)
                        setFilterSection(true)
                        dispatch(FilterMasterPricesReducer(date, dataIndex))
                    }}
                    value={datePicker}
                />
            </div>
        ),
        filterIcon: () => (<CalendarOutlined />),
    })

    const titleColumn = (columnName, column) => (
        <div
            className='Container-Title-Column-Approvals'
            onClick={async () => {
                let typeorderby
                if(rex_order_data_master_prices.orden == 'asc'){
                    typeorderby = 'desc'
                }else if(rex_order_data_master_prices.orden == 'desc'){
                    typeorderby = null
                }else{
                    typeorderby = 'asc'
                }
                await dispatch(OrderMasterPricesReducer(columnName, typeorderby))
                dispatch(GetDataMasterPricesReducer())
            }}
        >
            <span title={column}>{column}</span>
            <div>
                <CaretUpOutlined
                    className={`${rex_order_data_master_prices.orden == 'asc' && rex_order_data_master_prices.column == columnName ? 'Color-Icon-Blue' : 'Color-Icon-Gray'}`}
                />
                <CaretDownOutlined 
                    className={`${rex_order_data_master_prices.orden == 'desc' && rex_order_data_master_prices.column == columnName ? 'Color-Icon-Blue' : 'Color-Icon-Gray'}`}
                />
            </div>
        </div>
    )

    const columns = [
        {
            title: '#',
            dataIndex: 'key',
            render: (_,record) => <div title={record.key}>{record.key}</div>,
            width: '30px',
            align:'center'
        },
        {
            title: titleColumn('codigo', 'CODIGO MATERIAL'),
            dataIndex: 'codigo',
            ...getColumnSearchProps('codigo', 'Código material', rex_filter_data_master_prices.codigo),
            showSorterTooltip: false,
            render: (_, record) => <div title={record.codigo}>{record.codigo}</div>,
            width: '100px'
        },
        {
            title: titleColumn('cg2', 'CG2'),
            dataIndex: 'cg2',
            ...getColumnSearchProps('cg2', 'Cg2', rex_filter_data_master_prices.cg2),
            showSorterTooltip: false,
            render: (_, record) => <div title={record.cg2}>{record.cg2}</div>,
            width: '60px'
        },
        {
            title: titleColumn('exchange_value_1', 'EXCHANGE VALUE 1'),
            dataIndex: 'exchange_value_1',
            ...getColumnSearchProps('exchange_value_1', 'Exchange value 1', rex_filter_data_master_prices.exchange_value_1),
            showSorterTooltip: false,
            render: (_, record) => <div title={record.exchange_value_1}>{record.exchange_value_1}</div>,
            width: '100px'
        },
        {
            title: titleColumn('exchange_value_2', 'EXCHANGE VALUE 2'),
            dataIndex: 'exchange_value_2',
            ...getColumnSearchProps('exchange_value_2', 'Exchange value 2', rex_filter_data_master_prices.exchange_value_2),
            showSorterTooltip: false,
            render: (_, record) => <div title={record.exchange_value_2}>{record.exchange_value_2}</div>,
            width: '100px'
        },
        {
            title: titleColumn('exchange_value_3', 'EXCHANGE VALUE 3'),
            dataIndex: 'exchange_value_3',
            ...getColumnSearchProps('exchange_value_3', 'Exchange value 3', rex_filter_data_master_prices.exchange_value_3),
            showSorterTooltip: false,
            render: (_, record) => <div title={record.exchange_value_3}>{record.exchange_value_3}</div>,
            width: '100px'
        },
        {
            title: titleColumn('exchange_value_4', 'EXCHANGE VALUE 4'),
            dataIndex: 'exchange_value_4',
            ...getColumnSearchProps('exchange_value_4', 'Exchange value 4', rex_filter_data_master_prices.exchange_value_4),
            showSorterTooltip: false,
            render: (_, record) => <div title={record.exchange_value_4}>{record.exchange_value_4}</div>,
            width: '100px'
        },
        {
            title: titleColumn('exchange_value_5', 'EXCHANGE VALUE 5'),
            dataIndex: 'exchange_value_5',
            ...getColumnSearchProps('exchange_value_5', 'Exchange value 5', rex_filter_data_master_prices.exchange_value_5),
            showSorterTooltip: false,
            render: (_, record) => <div title={record.exchange_value_5}>{record.exchange_value_5}</div>,
            width: '100px'
        },
        {
            title: titleColumn('periodo', 'FECHA'),
            dataIndex: 'periodo',
            ...getColumnDateProps('periodo', 'Fecha', rex_filter_data_master_prices.periodo),
            showSorterTooltip: false,
            render: (_, record) =>  
                <div title={record.periodo ? record.periodo : ''}>
                    {record.periodo ? record.periodo : ''}
                </div>,
            width: '70px'
        },
    ]

    useEffect(() => {
        const timeOutInactividad = setTimeout(() => {
            if(filterSection){
                dispatch(GetDataMasterPricesReducer())
            }
        }, 1000)
    
        return () => clearTimeout(timeOutInactividad)
    }, [rex_filter_data_master_prices])

    return(
        <div
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={<Title className="Title-Module">Maestra de Precios</Title>}
                    className="Card-Item"
                >
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px'}}>
                        <div style={{display: 'flex', columnGap: '10px'}}>
                            <Button
                                style={{display:'flex', alignItems:'center', marginRight:'10px'}} size='small'
                                onClick={async ()=> {
                                    await dispatch(ClearMasterPricesReducer())
                                    dispatch(GetDataMasterPricesReducer())
                                }}     
                            >
                                <ReloadOutlined
                                    style={{fontSize:'14px', marginRight: '4px',color:'#1677ff',}}
                                />Recargar
                            </Button>
                        </div>
                        { rex_loading_data_master_prices && rex_data_master_prices.length == 0
                        ? <Skeleton.Input active={true} size='default'/>
                        : <div style={{display:'flex', columnGap: '10px', alignItems: 'center'}}>
                            <Pagination
                                current={rex_paginate_master_prices}
                                total={rex_register_total_master_prices}
                                pageSize={15}
                                onChange={async (page) => {
                                    await dispatch(PaginateMasterPricesReducer(page))
                                    dispatch(GetDataMasterPricesReducer())
                                }}
                                size='small'
                            />
                            <div>Total: {Intl.NumberFormat('en-IN').format(rex_register_total_master_prices)} registros</div> 
                        </div>
                        }
                    </div>

                    { rex_loading_data_master_prices && rex_data_master_prices.length == 0
                    ? <div style={{marginTop:'10px'}}>
                        <Skeleton/>
                        <Skeleton/>
                    </div>
                    : <Table 
                        pagination={{
                            position: ["none", "none"],
                            pageSize: 15,
                            current: rex_paginate_master_prices
                        }}
                        scroll={{
                            x: '1000px',
                            y: '430px',
                        }}
                        loading={rex_loading_data_master_prices}
                        columns={columns}
                        dataSource={rex_data_master_prices} 
                        className="Table-Home"
                        size='small'
                    />
                    }
                </Card>
            </Card>
        </div>
    )
}

export default MaestraPrices