import {
    GET_DATA_DATE,
} from "../../../Constans/DateData/DateData"

const INIT_STATE = {
    rex_data_date : [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_DATA_DATE: {
            return {
                ...state,
                rex_data_date : action.payload
            }
        }
        default:
            return state;
    }
}
