import { 
    OBTENER_DATA_NO_HOMOLOGADOS,
    OBTENER_DATA_NO_HOMOLOGADOS_CLONE
} from '../../../../Constans/Homologaciones/NoHomologados/AsignarNoHomologados'
import config from '../../../../config'

export const ObtenerSellInReducer = (
    prod_hml, index, prod_id,
    data_filtrar = {
        "req_pk_cod_pro" : "",
        "req_pk_cod_dist" : "",
        "req_type_day" : false,
        "req_type_month" : true,
        "req_type_year" : false,
        "req_anio" : [],
        "req_mes" : [],
        "req_dia" : [] 
    }
) => async ( dispatch, getState ) => {

    let { 
        rex_data_no_homologados_clone
    } = getState().asignarNoHomologados

    data_filtrar.req_pk_cod_pro = prod_hml.cod_producto
    data_filtrar.req_pk_cod_dist = rex_data_no_homologados_clone[index].codigo_distribuidor

    rex_data_no_homologados_clone[index]['producto_hml_seleccionado'] = prod_hml

    await fetch(config.api_url+'approvals/get-si',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify(data_filtrar),
        }
    )
    .then(res => res.json())
    .then(async data => {
        
        rex_data_no_homologados_clone[index]['sellin'] = data.data

        dispatch({
            type: OBTENER_DATA_NO_HOMOLOGADOS,
            payload: rex_data_no_homologados_clone
        })
        dispatch({
            type: OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
            payload: rex_data_no_homologados_clone
        })

    }).catch((error)=> {
        console.log(error)
    })

    return true
}

export const ObtenerSellOutReducer = (
    pk_extractor_venta_so,
    data_filtrar = {
        "req_type_day" : false,
        "req_type_month" : true,
        "req_type_year" : false,
        "req_pk_extractor_ventas_so" : "",
        "req_anio" : [],
        "req_mes" : [],
        "req_dia" : [] 
    }
) => async ( dispatch, getState ) => {

    data_filtrar.req_pk_extractor_ventas_so = pk_extractor_venta_so

    let data_so = []

    await fetch(config.api_url+'approvals/get-so',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify(data_filtrar),
        }
    )
    .then(res => res.json())
    .then(async data => {
        data_so = data.data
    }).catch((error)=> {
        console.log(error)
    })

    return data_so

}

export const ObtenerMaestraPreciosReducer = (
    cod_material_hml, index,
    data_filtrar = {
        "req_cod_material": "",
        "req_type_day" : false,
        "req_type_month" : false,
        "req_type_year" : false,
        "req_anio" : [],
        "req_mes" : [],
        "req_dia" : [] 
    }
) => async ( dispatch, getState ) => {

    let { 
        rex_data_no_homologados_clone,
    } = getState().asignarNoHomologados

    data_filtrar.req_cod_material = cod_material_hml

    await fetch(config.api_url+'approvals/get-master-price',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify(data_filtrar),
        }
    )
    .then(res => res.json())
    .then(async data => {

        rex_data_no_homologados_clone[index]['m_precios'] = data.data_final

        dispatch({
            type: OBTENER_DATA_NO_HOMOLOGADOS,
            payload: rex_data_no_homologados_clone
        })
        dispatch({
            type: OBTENER_DATA_NO_HOMOLOGADOS_CLONE,
            payload: rex_data_no_homologados_clone
        })

    }).catch((error)=> {
        console.log(error)
    })

    return true

}

export const ObtenerProductoHmlReducer = (proid_hml, index) => async ( dispatch, getState ) => {

    let { 
        rex_data_no_homologados_clone
    } = getState().asignarNoHomologados

    await fetch(config.api_url+'approvals/get-paqxbul-product',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                "req_proid" : proid_hml
            }),
        }
    )
    .then(res => res.json())
    .then(async data => {
        
        

    }).catch((error)=> {
        console.log(error)
    })

    return true

}

export const ObtenerInventarioReducer = (
    pk_extractor_venta_so,
    data_filtrar = {
        "req_type_day" : false,
        "req_type_month" : true,
        "req_type_year" : false,
        "req_pk_extractor_venta_so" : "",
        "req_anio" : [],
        "req_mes" : [],
        "req_dia" : [] 
    }
) => async ( dispatch, getState ) => {

    data_filtrar.req_pk_extractor_venta_so = pk_extractor_venta_so

    let data_inv = []

    await fetch(config.api_url+'approvals/get-inv',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify(data_filtrar),
        }
    )
    .then(res => res.json())
    .then(async data => {
        data_inv = data.data
    }).catch((error)=> {
        console.log(error)
    })

    return data_inv

}