import config from '../../../../config'
import { notification } from 'antd'
import {
    OBTENER_DATA_USUARIOS,
    OBTENER_DATA_EDITAR_USUARIO,
    DATA_NUEVO_USUARIO,
    CARGANDO_DATA_USUARIOS
} from '../../../../Constans/Administracion/Administrador/Usuarios'

const notificacionServidor = (type, mensaje, title=null) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: title?title:'Error',
            description: <div dangerouslySetInnerHTML={{ __html: mensaje }} />,
            duration: 10
        })
    }
}

export const ObtenerDataUsuariosReducer = () => async ( dispatch ) => {

    dispatch({
        type : CARGANDO_DATA_USUARIOS,
        payload : true
    })

    await fetch(config.api_url+'manage/get-users',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then( async res => {return res.json()})
    .then(data => {                
        dispatch({
            type: OBTENER_DATA_USUARIOS,
            payload: data.data
        })
    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type : CARGANDO_DATA_USUARIOS,
        payload : false
    })
}

export const ObtenerDataEditarUsuario = (usuid) => async ( dispatch, getState ) => {

    dispatch({
        type : OBTENER_DATA_EDITAR_USUARIO,
        payload : []
    })

    await fetch(config.api_url+'manage/get-user',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_usuid: parseInt(usuid)
            }),
        }
    )
    .then( async res => {return res.json()})
    .then(data => {                
        dispatch({
            type    : OBTENER_DATA_EDITAR_USUARIO,
            payload : data.data
        })
    }).catch((error)=> {
        console.log(error)
    })
}

export const EditarUsuarioReducer = () => async ( dispatch, getState ) => {

    const data_usu = getState().usuarios.rex_data_editar_usuario
    let response = true

    await fetch(config.api_url+'manage/edit-user',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_usuid               : data_usu.usuid,
                req_usucorreo           : data_usu.usucorreo,
                req_usuusuario          : data_usu.usuusuario,
                req_pernombre           : data_usu.perpersonas.pernombre,
                req_perapellidomaterno  : data_usu.perpersonas.perapellidomaterno,
                req_perapellidopaterno  : data_usu.perpersonas.perapellidopaterno,
                req_tpuid               : parseInt(data_usu.tpuid),
                req_estid               : data_usu.estid == true ? 1 : 2,
                req_azure               : data_usu.usu_azure,
                req_contrasenia         : data_usu.usucontrasenia,
                req_perid               : data_usu.perid,
                req_usutoken            : data_usu.usutoken
            }),
        }
    )
    .then( async res => {return res.json()})
    .then(data => {                
        if(data.response){
            dispatch(ObtenerDataUsuariosReducer())
            notificacionServidor('success', data.message)
        }else{
            response = false
        }
    }).catch((error)=> {
        console.log(error)
    })

    return response
}

export const EliminarUsuarioReducer = (usuid) => async ( dispatch, getState ) => {

    await fetch(config.api_url+'manage/delete-user',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_usuid       : usuid,
            }),
        }
    )
    .then( async res => {return res.json()})
    .then(data => {                
        if(data.response){
            dispatch(ObtenerDataUsuariosReducer())
            notificacionServidor('success', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })
}

export const CrearUsuarioReducer = () => async ( dispatch, getState ) => {

    const data_usu = getState().usuarios.rex_data_nuevo_usuario
    let respuesta = false

    await fetch(config.api_url+'manage/create-users',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                usu_correo              : data_usu.usucorreo,
                usu_usuario             : data_usu.usuusuario,
                per_nombre              : data_usu.perpersonas.pernombre,
                per_apellido_materno    : data_usu.perpersonas.perapellidomaterno,
                per_apellido_paterno    : data_usu.perpersonas.perapellidopaterno,
                tpu_id                  : parseInt(data_usu.tpuid),
                est_id                  : data_usu.estid == true ? 1 : 2,
                est_azure               : data_usu.usu_azure,
                usu_contrasenia         : data_usu.usucontrasenia,
                usu_token               : data_usu.usutoken
            }),
        }
    )
    .then( async res => {return res.json()})
    .then(data => {                
        if(data.respuesta){
            dispatch(ObtenerDataUsuariosReducer())
            notificacionServidor(data.alert ? 'warning': 'success', data.message)
        }
        if(data.respuesta && !data.alert){
            respuesta = true
        }
    }).catch((error)=> {
        console.log(error)
    })

    return respuesta
}

export const EditarCampoEditarUsuarioReducer = ( type, id_value, value ) => async ( dispatch, getState ) => {

    const usue = getState().usuarios.rex_data_editar_usuario

    switch (type) {
        case 'pernombre':
            usue['perpersonas']['pernombre'] = value
            break;
        case 'perapellidopaterno':
            usue['perpersonas']['perapellidopaterno'] = value
            break;
        case 'perapellidomaterno':
            usue['perpersonas']['perapellidomaterno'] = value
            break;
        case 'usuusuario':
            usue['usuusuario'] = value
            break;
        case 'usucorreo':
            usue['usucorreo'] = value
            break;
        case 'usutoken':
            usue['usutoken'] = value
            break;
        case 'usucontrasenia':
            usue['usucontrasenia'] = value
            break;
        case 'tpuid':
            usue['tpuid'] = value
            break;
        case 'estid':
            usue['estid'] = value
            break;
        case 'usu_azure':
            usue['usu_azure'] = value
            break;
        default:
          break
    }

    dispatch({
        type : OBTENER_DATA_EDITAR_USUARIO,
        payload : usue
    })
}

export const EditarCampoCrearUsuarioReducer = ( type, id_value, value ) => async ( dispatch, getState ) => {

    const usun = getState().usuarios.rex_data_nuevo_usuario

    switch (type) {
        case 'pernombre':
            usun['perpersonas']['pernombre'] = value
            break;
        case 'perapellidopaterno':
            usun['perpersonas']['perapellidopaterno'] = value
            break;
        case 'perapellidomaterno':
            usun['perpersonas']['perapellidomaterno'] = value
            break;
        case 'usuusuario':
            usun['usuusuario'] = value
            break;
        case 'usucorreo':
            usun['usucorreo'] = value
            break;
        case 'usutoken':
            usun['usutoken'] = value
            break;
        case 'usucontrasenia':
            usun['usucontrasenia'] = value
            break;
        case 'tpuid':
            usun['tpuid'] = value
            break;
        case 'estid':
            usun['estid'] = value
            break;
        case 'usu_azure':
            usun['usu_azure'] = value
            break;
        default:
          break
    }

    dispatch({
        type : DATA_NUEVO_USUARIO,
        payload : usun
    })
}