import config from '../../../../config'
import {
    GET_DATA_MASTER_PRICES,
    ORDER_DATA_MASTER_PRICES,
    FILTER_DATA_MASTER_PRICES,
    PAGINATE_MASTER_PRICES,
    REGISTER_TOTAL_MASTER_PRICES,
    LOADING_DATA_MASTER_PRICES,
} from '../../../../Constans/Administracion/MasterPrices/MasterPrices'

export const GetDataMasterPricesReducer = () => async (dispatch, getState) => {

    const {
        rex_paginate_master_prices,
        rex_order_data_master_prices,
        rex_filter_data_master_prices,
    } = getState().masterPrices

    dispatch({
        type: LOADING_DATA_MASTER_PRICES,
        payload : true
    })

    await fetch(config.api_url+'administration/get-master-prices?page='+rex_paginate_master_prices,
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_codigo              : rex_filter_data_master_prices.codigo ? rex_filter_data_master_prices.codigo : '',
                req_cg2                 : rex_filter_data_master_prices.cg2 ? rex_filter_data_master_prices.cg2 : '',
                req_exchange_value_1    : rex_filter_data_master_prices.exchange_value_1 ? rex_filter_data_master_prices.exchange_value_1 : '',
                req_exchange_value_2    : rex_filter_data_master_prices.exchange_value_2 ? rex_filter_data_master_prices.exchange_value_2 : '',
                req_exchange_value_3    : rex_filter_data_master_prices.exchange_value_3 ? rex_filter_data_master_prices.exchange_value_3 : '',
                req_exchange_value_4    : rex_filter_data_master_prices.exchange_value_4 ? rex_filter_data_master_prices.exchange_value_4 : '',
                req_exchange_value_5    : rex_filter_data_master_prices.exchange_value_5 ? rex_filter_data_master_prices.exchange_value_5 : '',
                req_periodo             : rex_filter_data_master_prices.periodo ? rex_filter_data_master_prices.periodo : '',
                req_updated_at          : rex_filter_data_master_prices.updated_at ? rex_filter_data_master_prices.updated_at : '',
                req_column              : rex_order_data_master_prices.column ? rex_order_data_master_prices.column : '',
                req_orden               : rex_order_data_master_prices.orden ? rex_order_data_master_prices.orden : '',
            })
        }
    )
    .then(res => res.json())
    .then(async data => {
        const data_master_prices = data.data
        await data_master_prices.map((aud, index) => {
            data_master_prices[index]["key"] = (15 * (rex_paginate_master_prices - 1)) + (index + 1)
        })

        dispatch({
            type: GET_DATA_MASTER_PRICES,
            payload: data_master_prices
        })

        dispatch({
            type: REGISTER_TOTAL_MASTER_PRICES,
            payload: data.data_total
        })
    }).catch((error)=> {
        console.log(error)
    })
    
    dispatch({
        type: LOADING_DATA_MASTER_PRICES,
        payload : false
    })
}

export const OrderMasterPricesReducer = (column, type) => async (dispatch) => {
    const filtro_orden = { column: column, orden : type }

    dispatch({
        type: ORDER_DATA_MASTER_PRICES,
        payload : filtro_orden
    })
}

export const FilterMasterPricesReducer = (text, type) => async (dispatch, getState) => {
    const { rex_filter_data_master_prices } = getState().masterPrices
    let text_audit = { ...rex_filter_data_master_prices, [type]: text }

    if(type == "periodo"){
        const date_format = text ? text.replace("-", "") : ''
        text_audit = { ...rex_filter_data_master_prices, [type]: date_format }
    }else if(type == "updated_at"){
        const date_format = text ? text.replace(/-/g, "/") : ''
        text_audit = { ...rex_filter_data_master_prices, [type]: date_format }
    }

    dispatch({
        type: FILTER_DATA_MASTER_PRICES,
        payload : text_audit
    })
}

export const PaginateMasterPricesReducer = (paginate) => async (dispatch) => {
    dispatch({
        type: PAGINATE_MASTER_PRICES,
        payload: paginate
    })
}

export const ClearMasterPricesReducer = () => async (dispatch) => {
    dispatch({
        type: PAGINATE_MASTER_PRICES,
        payload: 1
    })
    dispatch({
        type: FILTER_DATA_MASTER_PRICES,
        payload: {}
    })
    dispatch({
        type: ORDER_DATA_MASTER_PRICES,
        payload: { column: null, orden: null }
    })
}