import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Card, Input, Button, Form, Switch, Col, Row, Divider, Select, Space } from 'antd'
import { useParams, useNavigate } from 'react-router-dom'
import {
    LoadingOutlined,
    SaveOutlined,
    ArrowLeftOutlined,
    PlusOutlined
} from '@ant-design/icons'
import { ObtenerDataPermisoReducer } from '../../../Redux/Actions/Administracion/Administrador/Permisos'

const EditarPermiso = (props) => {
    const collapsed = props.collapsed

    const { form } = Form
    const { Title } = Typography
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()

    const [ editandoPermiso, setEditandoPermiso ] = useState(false)

    const {
        rex_data_permiso
    } = useSelector(({permisos}) => permisos)

    useEffect(() => {
        dispatch(ObtenerDataPermisoReducer(id))
    }, [])

    return (
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s',
                
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={<div style={{display:'flex'}}>
                        <Button type='primary' ghost onClick={() => navigate(`/admin/permisos`)} style={{marginRight:'10px'}}><ArrowLeftOutlined /></Button>
                        <Title level={4} style={{marginBottom: 0}}>Editar permiso</Title>
                        </div>}
                    className="Card-Item"
                    style={{padding:'10px 25px'}}
                >
                    <Form
                        form={form}
                        layout='vertical'
                        // onFinish={() => EditarPermisoTipoUsuario()}
                        className='Container-Form-Type-User'
                    >
                        <div>
                            <Row 
                                gutter={16}
                            >
                                <Divider orientation="left" plain>
                                    Datos del permiso:
                                </Divider>
                                <Col span={6}>
                                    <Form.Item
                                        rules={[
                                            { required: true, message: 'El nombre es necesario' },
                                        ]}
                                        label='Nombre:'
                                        // name='pemnombre'
                                    >
                                        <Input
                                            value={rex_data_permiso.pemnombre}
                                            autoComplete='off'
                                            // onChange={(e) => dispatch(EditarPermisoTipoUsuarioReducer('tpunombre', null, e.target.value))}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label='Slug:'
                                    >
                                        <Input
                                            name='pemslug'
                                            autoComplete='off'
                                            value={rex_data_permiso.pemslug}
                                            // onChange={(e) => dispatch(EditarPermisoTipoUsuarioReducer('tpuprivilegio', null, e.target.value))}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label='Ruta:'
                                    >
                                        <Input
                                            name='pemruta'
                                            autoComplete='off'
                                            value={rex_data_permiso.pemruta}
                                            // onChange={(e) => dispatch(EditarPermisoTipoUsuarioReducer('tpuprivilegio', null, e.target.value))}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label='Ruta:'
                                    >
                                    {/* <Select
                                        style={{
                                            width: 300,
                                        }}
                                        placeholder="custom dropdown render"
                                        dropdownRender={(menu) => (
                                            <>
                                            {menu}
                                            <Divider
                                                style={{
                                                margin: '8px 0',
                                                }}
                                            />
                                            <Space
                                                style={{
                                                padding: '0 8px 4px',
                                                }}
                                            >
                                                <Input
                                                    placeholder="Please enter item"
                                                    ref={inputRef}
                                                    value={name}
                                                    onChange={onNameChange}
                                                />
                                                <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                                Add item
                                                </Button>
                                            </Space>
                                            </>
                                        )}
                                        options={items.map((item) => ({
                                            label: item,
                                            value: item,
                                        }))}
                                        /> */}
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label='Permiso especial: '
                                    >
                                        <Switch
                                            // onChange={(value) => dispatch(EditarPermisoTipoUsuarioReducer('status',null, value))}
                                            size='small'
                                            checked={rex_data_permiso.pemespecial}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider orientation="left" plain>
                                Permisos:
                            </Divider>
                        </div>

                        <Form.Item className='Container-Button-Type-User'>
                            <Button 
                                danger
                                className='Button-Action-Type-User'
                                onClick={() => navigate(`/admin/permisos`)}
                            >
                                Cancelar
                            </Button>
                            <Button 
                                type="primary" 
                                ghost
                                htmlType="submit"
                                className='Button-Action-Type-User'
                            >
                                {editandoPermiso
                                ? <LoadingOutlined />
                                :<><SaveOutlined style={{marginRight:'6px'}}/>
                                    Guardar</>
                                }
                                
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Card>
        </div>
    )
}

export default EditarPermiso