import React, {useState, useEffect} from 'react'
import { Typography, Card, Button, Form, Select, Input, Row, Col, Switch, Skeleton } from 'antd'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    ArrowLeftOutlined,
    SaveOutlined,
    LoadingOutlined
} from '@ant-design/icons'
import { 
    ObtenerDataEditarUsuario,
    EditarCampoEditarUsuarioReducer, 
    EditarUsuarioReducer
} from '../../../../Redux/Actions/Administracion/Administrador/Usuarios'

const EditarUsuario = (props) => {

    const collapsed = props.collapsed
    const { Title } = Typography
    const navigate = useNavigate()
    const { id } = useParams()
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [ editandoUsuario, setEditandoUsuario ] = useState(false)
    const [ cargandoDataUsuario, setCargandoDataUsuario ] = useState(false)

    const { Option } = Select

    const {
        rex_data_editar_usuario
    } = useSelector(({usuarios}) => usuarios)

    const EditarUsuario = async () => {
        setEditandoUsuario(true)
        const response = await dispatch(EditarUsuarioReducer())
        setEditandoUsuario(false)
        if(response){
            navigate(`/admin/usuarios`)
        }
    }

    const ObtenerDataUsuario = async () => {
        setCargandoDataUsuario(true)
        await dispatch(ObtenerDataEditarUsuario(id))
        setCargandoDataUsuario(false)
    }

    useEffect(() => {
        ObtenerDataUsuario()
    }, [])

    useEffect(() => {
        form.setFieldsValue({
            pernombre           : rex_data_editar_usuario.perpersonas?.pernombre,
            perapellidopaterno  : rex_data_editar_usuario.perpersonas?.perapellidopaterno,
            usuusuario          : rex_data_editar_usuario.usuusuario,
            usucorreo           : rex_data_editar_usuario.usucorreo,
            tpu                 : rex_data_editar_usuario.value,
            usutoken            : rex_data_editar_usuario.usutoken
        })
    }, [rex_data_editar_usuario])

    
    return (
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={<div style={{display:'flex'}}>
                    <Button type='primary' ghost size='small' onClick={() => navigate(`/admin/usuarios`)} className="Button-Title-Module"><ArrowLeftOutlined /></Button>
                    <Title className="Title-Module">Editar Usuario</Title>
                        </div>}
                    className="Card-Item"
                >
                    {cargandoDataUsuario
                    ?<Skeleton />
                    :<Form
                        form={form}
                        layout='vertical'
                        onFinish={() => EditarUsuario()}
                        style={{height:'100%',justifyContent:'space-between'}}
                    >
                        <div>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item
                                        rules={[
                                            { required: true, message: 'El nombre es requerido' },
                                        ]}
                                        label='Nombres:'
                                        name='pernombre'
                                    >
                                        <Input
                                            value={rex_data_editar_usuario.perpersonas?.pernombre}
                                            onChange={(e) => dispatch(EditarCampoEditarUsuarioReducer('pernombre', null, e.target.value))}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        rules={[
                                            { required: true, message: 'El apellido paterno es requerido' },
                                        ]}
                                        label='Apellido paterno:'
                                        name='perapellidopaterno'
                                    >
                                        <Input
                                            value={rex_data_editar_usuario.perpersonas?.perapellidopaterno}
                                            onChange={(e) => dispatch(EditarCampoEditarUsuarioReducer('perapellidopaterno', null, e.target.value))}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label='Apellido materno:'
                                    >
                                        <Input
                                            value={rex_data_editar_usuario.perpersonas?.perapellidomaterno}                                        
                                            onChange={(e) => dispatch(EditarCampoEditarUsuarioReducer('perapellidomaterno', null, e.target.value))}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item
                                        rules={[
                                            { required: true, message: 'El usuario es necesario' },
                                        ]}
                                        name='usuusuario'
                                        label='Usuario:'
                                    >
                                        <Input
                                            value={rex_data_editar_usuario.usuusuario}
                                            onChange={(e) => dispatch(EditarCampoEditarUsuarioReducer('usuusuario', null, e.target.value))}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        rules={[
                                            { required: true, message: 'El correo es requerido' },
                                        ]}
                                        label='Correo:'
                                        name='usucorreo'
                                    >
                                        <Input
                                            value={rex_data_editar_usuario.usucorreo}
                                            onChange={(e) => dispatch(EditarCampoEditarUsuarioReducer('usucorreo', null, e.target.value))}

                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        rules={[
                                            { min:6, message: 'La contraseña debe tener mínimo 6 caracteres' },
                                        ]}
                                        name='contrasenia'
                                        label='Contraseña:'
                                    >
                                        <Input.Password
                                            autoComplete='off'
                                            onChange={(e) => dispatch(EditarCampoEditarUsuarioReducer('usucontrasenia', null, e.target.value))}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item
                                        label='Tipo de usuario:'
                                        name='tpu'
                                    >
                                    {
                                        Object.entries(rex_data_editar_usuario).length !== 0 && 
                                        <Select
                                            defaultValue={rex_data_editar_usuario && rex_data_editar_usuario.tpus.filter(tpus => tpus.tpu_selected == true).map(tpu => tpu.value)}
                                            onChange={(value) => dispatch(EditarCampoEditarUsuarioReducer('tpuid', null, value))}

                                        >
                                            {rex_data_editar_usuario.tpus.map((tpu) => (
                                                <Option key={tpu.tpuid}>{tpu.tpunombre}</Option>
                                            ))
                                        }
                                        </Select>
                                    }
                                        
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        // rules={[
                                        //     { min:3, message: 'El token debe tener mínimo 3 caracteres' },
                                        // ]}
                                        name='usutoken'
                                        label='Token:'
                                    >
                                        <Input
                                            autoComplete="new-password"
                                            value={rex_data_editar_usuario.usutoken}
                                            onChange={(e) => dispatch(EditarCampoEditarUsuarioReducer('usutoken', null, e.target.value))}
                                            disabled={true}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label='Estado:'
                                    >
                                        <Switch
                                            checked={rex_data_editar_usuario && rex_data_editar_usuario.estid == 1 ? true : false}
                                            onChange={(value) => dispatch(EditarCampoEditarUsuarioReducer('estid', null, value))}
                                            size='small'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label='Usuario Azure:'
                                    >
                                        <Switch
                                            checked={rex_data_editar_usuario && rex_data_editar_usuario.usu_azure}
                                            onChange={(value) => dispatch(EditarCampoEditarUsuarioReducer('usu_azure', null, value))}
                                            size='small'
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <Form.Item className='Container-Button-Type-User'>
                            <Button 
                                danger
                                className='Button-Action-Type-User'
                                onClick={() => navigate(`/admin/usuarios`)}
                            >
                                Cancelar
                            </Button>
                            <Button 
                                type="primary" 
                                ghost
                                htmlType="submit"
                                className='Button-Action-Type-User'
                            >
                                { editandoUsuario
                                ? <LoadingOutlined />
                                : <>
                                    <SaveOutlined style={{marginRight:'6px'}}/>
                                        Guardar
                                  </>
                                }
                            </Button>
                        </Form.Item>
                    </Form>
                    }
                </Card>
            </Card>
        </div>
    )
}

export default EditarUsuario