import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ClearFilterOrderTextReducer, FilterAuditsReducer, FilterOrderAuditsReducer, FilterTextAuditsReducer, GetAuditsReducer, PaginateAuditsReducer } from '../../Redux/Actions/Audits/Audits'
import { ObtenerDataUsuariosReducer } from '../../Redux/Actions/Administracion/Administrador/Usuarios'
import { Card, Typography, Table, Button, Input, Select, DatePicker, Pagination, Skeleton } from 'antd'
import {
    FilterOutlined,
    ReloadOutlined,
    CalendarOutlined,
    CaretUpOutlined,
    CaretDownOutlined,
} from '@ant-design/icons'
import { format, parseISO } from 'date-fns'
import moment from 'moment'
import '../../Styles/Routes/Audits/Audits.css'

const Audits = (props) => {
    const collapsed = props.collapsed
    const { Title } = Typography

    const [ filterSection, setFilterSection ] = useState(false)
    const [ valueDate, setValueDate ] = useState("")
    const [ valueDatePicker, setValueDatePicker ] = useState(undefined)
    const [ valueModule, setValueModule ] = useState(undefined)
    const [ valueUser, setValueUser ] = useState(undefined)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(GetAuditsReducer())
        dispatch(ObtenerDataUsuariosReducer())
        // dispatch(GetDataDateReducer())
    }, [])

    const {
        rex_data_audits_clone,
        rex_data_audits_module,
        rex_loading_data_audits,
        rex_filter_text_audit,
        rex_filter_order_audit,
        rex_paginate_audit,
        rex_total_register_audit,
    } = useSelector(({audits}) => audits)

    const {
        rex_data_usuarios
    } = useSelector(({usuarios}) => usuarios)

    const getColumnSearchProps = (dataIndex, texto) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{padding: 8,}}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Buscar ${texto}`}
                    onChange={async (e) => {
                        confirm({
                            closeDropdown: false,
                        })
                        setFilterSection(true)
                        await dispatch(FilterTextAuditsReducer(e.target.value, dataIndex))
                    }}
                    style={{display: 'block'}}
                />
            </div>
        ),
        filterIcon: (filtered) => (<FilterOutlined />),
        onFilter: (value, record) => {
            let valueData
            if(dataIndex.length == 3){
                valueData = record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
            }else if(dataIndex.length == 2){
                valueData = record[dataIndex[0]][dataIndex[1]]
            }else if(dataIndex.length == 1){
                if(dataIndex[0] == "created_at"){
                    valueData = format(parseISO(record[dataIndex[0]]), 'dd/MM/yyyy')
                }else{
                    valueData = record[dataIndex[0]]
                }
            }
            return valueData ? valueData.toString().toLowerCase().includes(value.toLowerCase()) : false
        }
    })

    const getColumnDateProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <DatePicker
                    allowClear={true}
                    onChange={(value)=> {
                        setFilterSection(true)
                        dispatch(FilterTextAuditsReducer(value, dataIndex))
                    }}
                />
            </div>
        ),
        filterIcon: (filtered) => (
            <CalendarOutlined />
        ),
    })

    const titleColumn = (columnName, column) => (
        <div
            className='Container-Title-Column-Approvals'
            onClick={async () => {
                let typeorderby
                if(rex_filter_order_audit.typeorderby == 'asc'){
                    typeorderby = 'desc'
                }else if(rex_filter_order_audit.typeorderby == 'desc'){
                    typeorderby = null
                }else{
                    typeorderby = 'asc'
                }
                await dispatch(FilterOrderAuditsReducer(columnName, typeorderby))
                dispatch(GetAuditsReducer())
            }}
        >
            <span title={column}>{column}</span>
            <div>
                <CaretUpOutlined
                    className={`${rex_filter_order_audit.typeorderby == 'asc' && rex_filter_order_audit.ordenby == columnName ? 'Color-Icon-Blue' : 'Color-Icon-Gray'}`}
                />
                <CaretDownOutlined 
                    className={`${rex_filter_order_audit.typeorderby == 'desc' && rex_filter_order_audit.ordenby == columnName ? 'Color-Icon-Blue' : 'Color-Icon-Gray'}`}
                />
            </div>
        </div>
    )

    const columns = [
        {
            title: '#',
            dataIndex: 'key',
            render: (_,record) => <div title={record.key}>{record.key}</div>,
            width: '50px',
            align:'center'
        },
        {
            title: titleColumn('tpunombre', 'TIPO DE PERFIL'),
            dataIndex: 'usuusuarios',
            ...getColumnSearchProps(['usuusuarios', 'tputiposusuarios', 'tpunombre'], 'Tipo de Perfil'),
            showSorterTooltip: false,
            render: (_, record) => <div title={record.usuusuarios ? record.usuusuarios.tputiposusuarios.tpunombre : ''}>
                                        {record.usuusuarios ? record.usuusuarios.tputiposusuarios.tpunombre : ''}
                                    </div>,
            width: '110px'
        },
        {
            title: titleColumn('pernombrecompleto', 'NOMBRE COMPLETO'),
            dataIndex: 'usuusuarios.perpersonas',
            ...getColumnSearchProps(['usuusuarios', 'perpersonas', 'pernombrecompleto'], 'Nombre Completo'),
            showSorterTooltip: false,
            render: (_,record) => <div title={record.usuusuarios.perpersonas.pernombrecompleto}>{record.usuusuarios.perpersonas.pernombrecompleto}</div>,
            width: '150px'
        },
        {
            title: titleColumn('usuusuario', 'CORREO'),
            dataIndex: 'usuusuarios.usuusuario',
            ...getColumnSearchProps(['usuusuarios', 'usuusuario'], 'Correo'),
            showSorterTooltip: false,
            render: (_, record) => <div title={record.usuusuarios.usuusuario}>{record.usuusuarios.usuusuario}</div>,
            width: '120px'
        },
        {
            title: titleColumn('auddescripcion', 'MÓDULO'),
            dataIndex: 'auddescripcion',
            ...getColumnSearchProps(['auddescripcion'], 'Modulo'),
            showSorterTooltip: false,
            render: (_, record) => <div title={record.auddescripcion}>{record.auddescripcion}</div>,
            width: '100px'
        },
        {
            title: titleColumn('audruta', 'RUTA'),
            dataIndex: 'audruta',
            ...getColumnSearchProps(['audruta'], 'Ruta'),
            showSorterTooltip: false,
            render: (_, record) => <div title={record.audruta}>{record.audruta}</div>,
            width: '100px'
        },
        {
            title: titleColumn('audaccion', 'ACCIÓN'),
            dataIndex: 'audaccion',
            ...getColumnSearchProps(['audaccion'], 'Acción'),
            showSorterTooltip: false,
            render: (_, record) => <div title={record.audaccion}>{record.audaccion}</div>,
            width: '100px'
        },
        {
            title: titleColumn('audjsonentrada', 'ENTRADA'),
            dataIndex: 'audjsonentrada',
            ...getColumnSearchProps(['audjsonentrada'], 'Entrada'),
            showSorterTooltip: false,
            render: (_, record) => <div title={record.audjsonentrada}>{record.audjsonentrada}</div>,
            width: '100px'
        },
        {
            title: titleColumn('audjsonsalida', 'SALIDA'),
            dataIndex: 'audjsonsalida',
            ...getColumnSearchProps(['audjsonsalida'], 'Salida'),
            showSorterTooltip: false,
            render: (_, record) => <div title={record.audjsonsalida}>{record.audjsonsalida}</div>,
            width: '100px'
        },
        {
            title: titleColumn('created_at', 'FECHA Y HORA'),
            dataIndex: 'created_at',
            ...getColumnDateProps(['created_at'], 'Fecha y hora'),
            showSorterTooltip: false,
            render: (_, record) =>  
                <div title={record.created_at ? moment(record.created_at).format('DD/MM/YYYY h:mm a') : ''}>
                    {record.created_at ? moment(record.created_at).format('DD/MM/YYYY h:mm a') : ''}
                </div>,
            width: '120px'
        },
    ]

    useEffect(() => {
        const timeOutInactividad = setTimeout(() => {
            if(filterSection){
                dispatch(GetAuditsReducer())
            }
        }, 1000)
    
        return () => clearTimeout(timeOutInactividad)
    }, [rex_filter_text_audit])

    return(
        <div
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={<Title className="Title-Module">Control de Auditorias</Title>}
                    className="Card-Item"
                >
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px'}}>
                        <div style={{display: 'flex', columnGap: '10px'}}>
                            <Button
                                style={{display:'flex', alignItems:'center', marginRight:'10px'}} size='small'
                                onClick={async ()=> {
                                    setValueDate("")
                                    setValueModule(undefined)
                                    setValueUser(undefined)
                                    setValueDatePicker(undefined)
                                    await dispatch(ClearFilterOrderTextReducer())
                                    dispatch(GetAuditsReducer())
                                }}     
                            >
                                <ReloadOutlined
                                    style={{fontSize:'14px', marginRight: '4px',color:'#1677ff',}}
                                />Recargar
                            </Button>
                            {/* <DatePicker 
                                className="Date-Audits"
                                onChange={async (e, date) => {
                                    const dateSelect = date ? date : ""
                                    let date_format_start = ""
                                    let date_format_end = ""
                                    if(dateSelect){
                                        date_format_start = moment(date).startOf('month').format('YYYY-MM-DD')
                                        date_format_end = moment(date).endOf('month').format('YYYY-MM-DD')
                                        setValueDate({date_format_start, date_format_end})
                                        setValueDatePicker(moment(dateSelect))
                                    }else{
                                        setValueDate("")
                                    }
                                    setFilterSection(false)
                                    await dispatch(FilterAuditsReducer({date_format_start, date_format_end}, valueModule, valueUser))
                                    dispatch(GetAuditsReducer())
                                }}
                                value={valueDatePicker}
                                allowClear
                                picker='month'
                                size='small'
                            /> */}
                            <Select
                                showSearch
                                allowClear
                                placeholder="Filtro por Módulo"
                                onChange={async (e) => {
                                    const modValue = e ? e : ''
                                    setValueModule(modValue)
                                    setFilterSection(false)
                                    await dispatch(FilterAuditsReducer(valueDate, modValue, valueUser))
                                    dispatch(GetAuditsReducer())
                                }}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                size='small'
                                style={{width: '150px'}}
                                value={valueModule}
                                options={
                                    rex_data_audits_module.map(mod => {
                                        return {
                                            value: mod,
                                            label: mod,
                                        }
                                    })
                                }
                            />
                            <Select
                                showSearch
                                allowClear
                                placeholder="Filtro por Usuario"
                                onChange={async (e, data) => {
                                    const usuValue = data ? data.label : ''
                                    setValueUser(usuValue)
                                    setFilterSection(false)
                                    await dispatch(FilterAuditsReducer(valueDate, valueModule, usuValue))
                                    dispatch(GetAuditsReducer())
                                }}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                size='small'
                                style={{width: '170px'}}
                                value={valueUser}
                                options={
                                    rex_data_usuarios.map(usu => {
                                        return {
                                            value: usu.usuid,
                                            label: usu.usucorreo
                                        }
                                    })
                                }
                            />
                        </div>
                        { rex_loading_data_audits && rex_data_audits_clone.length == 0
                        ? <Skeleton.Input active={true} size='default'/>
                        : <div style={{display:'flex', columnGap: '10px', alignItems: 'center'}}>
                            <Pagination
                                current={rex_paginate_audit}
                                total={rex_total_register_audit}
                                pageSize={15}
                                onChange={async (page, pageSize) => {
                                    await dispatch(PaginateAuditsReducer(page))
                                    dispatch(GetAuditsReducer())
                                }}
                                size='small'
                            />
                            <div>Total: {Intl.NumberFormat('en-IN').format(rex_total_register_audit)} registros</div> 
                        </div>
                        }
                    </div>

                    { rex_loading_data_audits && rex_data_audits_clone.length == 0
                    ? <div style={{marginTop:'10px'}}>
                        <Skeleton/>
                        <Skeleton/>
                    </div>
                    : <Table 
                        pagination={{
                            position: ["none", "none"],
                            pageSize: 15,
                            current: rex_paginate_audit
                        }}
                        scroll={{
                            x: '1000px',
                            y: '430px',
                        }}
                        loading={rex_loading_data_audits}
                        columns={columns}
                        dataSource={rex_data_audits_clone} 
                        className="Table-Home"
                        size='small'
                    />
                    }
                </Card>
            </Card>
        </div>
    )
}

export default Audits