import config from '../../../../config'
import { notification } from 'antd'
import {
    GET_UPLOAD_DATA_RESTRICTION,
    DATA_UPLOAD_DATA_RESTRICTION,
    DATA_UPDATE_UPLOAD_DATA_RESTRICTION,
    OPEN_MODAL_UPLOAD_DATA_RESTRICTION,
} from '../../../../Constans/Administracion/Administrador/UploadDataRestriction'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const GetUploadDataRestriction = () => async (dispatch) => {
    await fetch(config.api_url+'manage/get-upload-data-restriction',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            const get_data = data.data
            const form_data = {
                archivoplanoso : get_data.find(dat => dat.tcanombre == "Archivo Plano SO"),
                sellout : get_data.find(dat => dat.tcanombre == "Sell Out"),
                sellinthanos : get_data.find(dat => dat.tcanombre == "Sell In Thanos"),
                masterproductos : get_data.find(dat => dat.tcanombre == "Master de Producto"),
                masterclientes : get_data.find(dat => dat.tcanombre == "Master de Clientes"),
                masterprecios : get_data.find(dat => dat.tcanombre == "Master de Precios"),
            }
            dispatch({
                type: GET_UPLOAD_DATA_RESTRICTION,
                payload: get_data
            })
            dispatch({
                type: DATA_UPLOAD_DATA_RESTRICTION,
                payload: form_data
            })
        }
    }).catch((error)=> {
        console.log(error)
    })
}

export const ChangeUpdateUploadDataRestriction = (value, typeupdate, name) => async (dispatch, getState) => {
    const { rex_get_upload_data_restriction, rex_data_update_upload_data_restriction } = getState().uploadDataRestriction
    const get_data = rex_get_upload_data_restriction.find(upl => upl.tcanombre == name)
    let change_data = null

    if(typeupdate == "archivoplanoso"){
        change_data = {
            ...rex_data_update_upload_data_restriction,
            archivoplanoso: {
                tcaid: get_data.tcaid,
                tcadiarestriccion: value,
            }
        }
    }else if(typeupdate == "sellout"){
        change_data = {
            ...rex_data_update_upload_data_restriction,
            sellout: {
                tcaid: get_data.tcaid,
                tcadiarestriccion: value,
            }
        }
    }else if(typeupdate == "sellinthanos"){
        change_data = {
            ...rex_data_update_upload_data_restriction,
            sellinthanos: {
                tcaid: get_data.tcaid,
                tcadiarestriccion: value,
            }
        }
    }else if(typeupdate == "masterproductos"){
        change_data = {
            ...rex_data_update_upload_data_restriction,
            masterproductos: {
                tcaid: get_data.tcaid,
                tcadiarestriccion: value,
            }
        }
    }else if(typeupdate == "masterclientes"){
        change_data = {
            ...rex_data_update_upload_data_restriction,
            masterclientes: {
                tcaid: get_data.tcaid,
                tcadiarestriccion: value,
            }
        }
    }else if(typeupdate == "masterprecios"){
        change_data = {
            ...rex_data_update_upload_data_restriction,
            masterprecios: {
                tcaid: get_data.tcaid,
                tcadiarestriccion: value,
            }
        }
    }

    dispatch({
        type: DATA_UPDATE_UPLOAD_DATA_RESTRICTION,
        payload: change_data
    })
}

export const UpdateUploadDataRestriction = () => async (dispatch, getState) => {
    const { rex_data_update_upload_data_restriction } = getState().uploadDataRestriction
    let data = []
    if(rex_data_update_upload_data_restriction.archivoplanoso){
        data.push(rex_data_update_upload_data_restriction.archivoplanoso)
    }else if(rex_data_update_upload_data_restriction.sellout){
        data.push(rex_data_update_upload_data_restriction.sellout)
    }else if(rex_data_update_upload_data_restriction.sellinthanos){
        data.push(rex_data_update_upload_data_restriction.sellinthanos)
    }else if(rex_data_update_upload_data_restriction.masterproductos){
        data.push(rex_data_update_upload_data_restriction.masterproductos)
    }else if(rex_data_update_upload_data_restriction.masterclientes){
        data.push(rex_data_update_upload_data_restriction.masterclientes)
    }else if(rex_data_update_upload_data_restriction.masterprecios){
        data.push(rex_data_update_upload_data_restriction.masterprecios)
    }

    let response = true
    
    await fetch(config.api_url+'manage/update-upload-data-restriction',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_data: data
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            notificacionServidor('success', data.message)
        }else{
            notificacionServidor('error', data.message)
            response = false
        }
    }).catch((error)=> {
        console.log(error)
    })

    return response
}

export const ChangeModalUploadDataRestriction = (value) => async (dispatch) => {
    dispatch({
        type : OPEN_MODAL_UPLOAD_DATA_RESTRICTION,
        payload : value
    })
}