import React, {useState, useEffect} from 'react'
import { Typography, Card, Button, Form, Select, Input, Row, Col, Switch } from 'antd'
import {
    ArrowLeftOutlined,
    LoadingOutlined,
    SaveOutlined
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { 
    CrearUsuarioReducer, 
    EditarCampoCrearUsuarioReducer 
} from '../../../../Redux/Actions/Administracion/Administrador/Usuarios'
import { useDispatch, useSelector } from 'react-redux'
import { 
    ObtenerDataTiposUsuariosReducer 
} from '../../../../Redux/Actions/Administracion/Administrador/TiposUsuarios'

const CrearUsuario = (props) => {

    const collapsed     = props.collapsed
    const { Title }     = Typography
    const { Option }    = Select

    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const [ creandoUsuario, setCreandoUsuario ] = useState(false)
    const [form] = Form.useForm()

    const {
        rex_data_tipos_usuarios
    } = useSelector(({tiposUsuarios}) => tiposUsuarios)

    const {
        rex_data_nuevo_usuario
    } = useSelector(({usuarios}) => usuarios)

    const CrearUsuario = async () => {
        setCreandoUsuario(true)
        const respuesta = await dispatch(CrearUsuarioReducer())
        if(respuesta){
            form.setFieldsValue({
                pernombre           : '',
                perapellidopaterno  : '',
                perapellidomaterno  : '',
                usuusuario          : '',
                usucorreo           : '',
                contrasenia         : '',
                tpu                 : null,
            })
            navigate(`/admin/usuarios`)
        }
        setCreandoUsuario(false)
    }

    useEffect(() => {
        dispatch(ObtenerDataTiposUsuariosReducer())
    },[])

    return (
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={<div style={{display:'flex'}}>
                    <Button type='primary' ghost size='small' onClick={() => navigate(`/admin/usuarios`)} className="Button-Title-Module"><ArrowLeftOutlined /></Button>
                    <Title className="Title-Module">Crear Usuario</Title>
                        </div>}
                    className="Card-Item"
                >
                    <Form
                        form={form}
                        layout='vertical'
                        onFinish={() => CrearUsuario()}
                        autoComplete='off'
                        style={{height:'100%',justifyContent:'space-between'}}
                    >
                        <div>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item
                                        rules={[
                                            { required: true, message: 'El nombre es requerido' },
                                        ]}
                                        label='Nombres:'
                                        name='pernombre'
                                    >
                                        <Input
                                            value={rex_data_nuevo_usuario.perpersonas?.pernombre}
                                            onChange={(e) => dispatch(EditarCampoCrearUsuarioReducer('pernombre', null, e.target.value))}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        rules={[
                                            { required: true, message: 'El apellido paterno es requerido' },
                                        ]}
                                        label='Apellido paterno:'
                                        name='perapellidopaterno'
                                    >
                                        <Input
                                            value={rex_data_nuevo_usuario.perpersonas?.perapellidopaterno}
                                            onChange={(e) => dispatch(EditarCampoCrearUsuarioReducer('perapellidopaterno', null, e.target.value))}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label='Apellido materno:'
                                        name='perapellidomaterno'
                                    >
                                        <Input
                                            value={rex_data_nuevo_usuario.perpersonas?.perapellidomaterno}                                        
                                            onChange={(e) => dispatch(EditarCampoCrearUsuarioReducer('perapellidomaterno', null, e.target.value))}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item
                                        rules={[
                                            { required: true, message: 'El usuario es necesario' },
                                        ]}
                                        name='usuusuario'
                                        label='Usuario:'
                                    >
                                        <Input
                                            autoComplete="new-password"
                                            value={rex_data_nuevo_usuario.usuusuario}
                                            onChange={(e) => dispatch(EditarCampoCrearUsuarioReducer('usuusuario', null, e.target.value))}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        rules={[
                                            { required: true, message: 'El correo es requerido' },
                                        ]}
                                        label='Correo:'
                                        name='usucorreo'
                                    >
                                        <Input
                                            value={rex_data_nuevo_usuario.usucorreo}
                                            onChange={(e) => dispatch(EditarCampoCrearUsuarioReducer('usucorreo', null, e.target.value))}

                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        rules={[
                                            { min:6, message: 'La contraseña debe tener mínimo 6 caracteres' },
                                            { required: true, message: 'La contraseña es requerida' },
                                        ]}
                                        name='contrasenia'
                                        label='Contraseña:'
                                    >
                                        <Input.Password
                                            autoComplete="new-password"
                                            onChange={(e) => dispatch(EditarCampoCrearUsuarioReducer('usucontrasenia', null, e.target.value))}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item
                                        label='Tipo de usuario:'
                                        name='tpu'
                                        rules={[
                                            { required: true, message: 'El tipo de usuario es requerido' },
                                        ]}
                                    >
                                        {
                                            Object.entries(rex_data_tipos_usuarios).length !== 0 && 
                                            <Select
                                                placeholder='Seleccionar'
                                                onChange={(value) => dispatch(EditarCampoCrearUsuarioReducer('tpuid', null, value))}

                                            >
                                                { rex_data_tipos_usuarios.map((tpu) => (
                                                    <Option key={tpu.tpuid}>{tpu.tpunombre}</Option>
                                                ))}
                                            </Select>
                                        }
                                        
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        // rules={[
                                        //     { min:3, message: 'El token debe tener mínimo 3 caracteres' },
                                        // ]}
                                        name='usutoken'
                                        label='Token:'
                                    >
                                        <Input
                                            autoComplete="new-password"
                                            onChange={(e) => dispatch(EditarCampoCrearUsuarioReducer('usutoken', null, e.target.value))}
                                            disabled={true}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label='Estado:'
                                    >
                                        <Switch
                                            checked={rex_data_nuevo_usuario.estid}
                                            onChange={(value) => dispatch(EditarCampoCrearUsuarioReducer('estid', null, value))}
                                            size='small'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label='Usuario Azure:'
                                    >
                                        <Switch
                                            checked={rex_data_nuevo_usuario.usu_azure}
                                            onChange={(value) => dispatch(EditarCampoCrearUsuarioReducer('usu_azure', null, value))}
                                            size='small'
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <Form.Item className='Container-Button-Type-User'>
                            <Button 
                                danger
                                className='Button-Action-Type-User'
                                onClick={() => navigate(`/admin/usuarios`)}
                            >
                                Cancelar
                            </Button>
                            <Button 
                                type="primary" 
                                ghost
                                htmlType="submit"
                                className='Button-Action-Type-User'
                            >
                                { creandoUsuario
                                ? <LoadingOutlined />
                                : <>
                                    <SaveOutlined style={{marginRight:'6px'}}/>
                                        Guardar
                                  </>
                                }
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Card>
        </div>
    )
}

export default CrearUsuario