import React, {useState} from 'react'
import { 
    Button, Modal, Upload, Switch, Tooltip 
} from 'antd'
import {
    UploadOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons';
import {
    CargarClientesReducer
} from '../../Redux/Actions/CargarData/CargarData'
import { useDispatch } from 'react-redux'

const ModalClientes = (props) => {

    const dispatch = useDispatch()

    const [uploading, setUploading] = useState(false)
    const [fileList, setFileList] = useState([])
    const [ deleteData, setDeleteData ] = useState(false)

    const isModalOpen = props.isModalOpen
    const setIsModalOpen = props.setIsModalOpen

    const props_upload = {
        onRemove: (file) => {
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          setFileList(newFileList);
        },
        beforeUpload: (file) => {
          setFileList([...fileList, file]);
          return false;
        },
        fileList,
    };

    return (
        <div>

            <Modal 
                title="Cargar Maestra de Clientes" 
                open={isModalOpen} 
                onCancel={() => setIsModalOpen(false)}
                centered={true}
                footer={null}
                width={550}
            >

                <Button
                    type="primary"
                    onClick={() => {
                        const fileUrl = 'https://backend-subsidios.grow-solutions.com/hmlthanos/plantillas/Plantilla Maestra de Cliente.xlsx';
                        const link = document.createElement('a');
                        link.href = fileUrl;
                        link.download = 'Plantilla Maestra de Cliente.xlsx';
                        link.click();
                    }}
                    style={{ marginTop: 16, marginBottom:'20px' }}
                >
                    Descargar Plantilla
                </Button><br/>

                <div style={{display:'flex', alignItems:'center'}}>
                    <Upload {...props_upload}>
                        <Button icon={<UploadOutlined />}>Seleccionar Archivo</Button>
                    </Upload>
                    {/* <div style={{marginLeft:'10px'}}>
                        <Switch
                            onChange={() => setDeleteData(!deleteData)}
                        />
                        <span style={{margin:'0 10px'}}>Sobreescribir data</span>
                        <Tooltip title="Se sobreescribirá toda la data existente">
                            <QuestionCircleOutlined/>
                        </Tooltip>
                    </div> */}
                </div>

                <Button
                    type="primary"
                    onClick={async () => {
                        setUploading(true)
                        const rptaCarga = await dispatch(CargarClientesReducer(fileList, deleteData))
                        if(rptaCarga == true){
                            setFileList([])
                            setIsModalOpen(false)
                        }

                        setFileList([])
                        setUploading(false)
                    }}
                    disabled={fileList.length === 0}
                    loading={uploading}
                    style={{ marginTop: 16 }}
                >
                    {uploading ? 'Cargando' : 'Subir Archivo'}
                </Button>


            </Modal>
            
        </div>
    )
}

export default ModalClientes