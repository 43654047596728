import config from '../../../../config'
import {
    OBTENER_DATA_HOMOLOGADOS,
    OBTENER_DATA_CANTIDAD_HOMOLOGADOS,
    CARGANDO_DATA_HOMOLOGADOS,
    FILTRO_INPUT_DATA_HOMOLOGADOS,
    FILTRO_ORDEN_DATA_HOMOLOGADOS,
    PAGINA_TABLA_DATA_HOMOLOGADOS,
    MOSTRAR_OTROS_HOMOLOGADOS,
    FILTRO_TIPOS_CONEXIONES_HOMOLOGADOS
} from '../../../../Constans/Homologaciones/Homologados/Homologados'
import { notification } from 'antd'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const ObtenerHomologadosReducer = (filtroInput = false) => async ( dispatch, getState ) => {

    const pag_tabla_homologados             = getState().homologados.rex_pagina_tabla_data_homologados
    const filtro_orden_homologados          = getState().homologados.rex_filtro_orden_data_homologados
    const filtro_input_data_homologados     = getState().homologados.rex_filtro_input_data_homologados
    const otros_homologados                 = getState().homologados.rex_mostrar_otros_homologados

    const data_cantidad_homologados = getState().homologados.rex_data_cantidad_homologados
    const total = data_cantidad_homologados == null || filtroInput ? true : false

    dispatch({
        type : CARGANDO_DATA_HOMOLOGADOS,
        payload : true
    })

    await fetch(config.api_url+'approvals/get-approved-products?page='+pag_tabla_homologados+'&total='+total,
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_regional_gba    : filtro_input_data_homologados?.regional_gba          ? filtro_input_data_homologados.regional_gba   : '',
                req_zone            : filtro_input_data_homologados?.zone                ? filtro_input_data_homologados.zone         : '',
                req_attr_1          : filtro_input_data_homologados?.attr_1             ? filtro_input_data_homologados.attr_1 : '',
                req_cod_prod_not    : filtro_input_data_homologados?.codigo_destinatario ? filtro_input_data_homologados.codigo_destinatario  : '',
                req_pro_not_hml     : filtro_input_data_homologados?.descripcion_producto? filtro_input_data_homologados.descripcion_producto : '',
                req_cod_pro_hml     : filtro_input_data_homologados?.codigo_material     ? filtro_input_data_homologados.codigo_material  : '',
                req_prod_hml        : filtro_input_data_homologados?.material     ? filtro_input_data_homologados.material  : '',
                req_orden           : filtro_orden_homologados?.orden                    ? filtro_orden_homologados.orden    : null,
                req_column          : filtro_orden_homologados?.column                   ? filtro_orden_homologados.column   : null,
                req_usuusuario      : filtro_input_data_homologados?.usuusuario   ? filtro_input_data_homologados.usuusuario  : '',
                req_filtro_input    : filtroInput,
                req_cliente_hml     : filtro_input_data_homologados?.cliente_hml         ? filtro_input_data_homologados.cliente_hml  : '',
                req_client_sub_hml  : filtro_input_data_homologados?.client_sub_hml         ? filtro_input_data_homologados.client_sub_hml  : '',
                req_updated_at      : filtro_input_data_homologados?.fecha_homologado    ? filtro_input_data_homologados.fecha_homologado   : '',
                req_otros           : otros_homologados,
            }),
        }
    )
    .then( async res => {return res.json()})
    .then(data => {              
        if(data.respuesta){
            dispatch({
                type: OBTENER_DATA_HOMOLOGADOS,
                payload: data.data
            })

            if(total || filtroInput){
                dispatch({
                    type: OBTENER_DATA_CANTIDAD_HOMOLOGADOS,
                    payload: data.total
                })
            }

            let filter_connection = []
            data.filter_connection.map(fil => {
                filter_connection.push({label:fil.attr_1, value : fil.attr_1})
            })
            dispatch({
                type: FILTRO_TIPOS_CONEXIONES_HOMOLOGADOS,
                payload: filter_connection
            })
        }
    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type : CARGANDO_DATA_HOMOLOGADOS,
        payload : false
    })
}

export const ActualizarHomologacionReducer = (producto_so_id, producto_id, nuevaFecha, cod_producto_hml, uni_medida) => async ( dispatch ) => {
    await fetch(config.api_url+'approvals/upload-approved',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                producto_so_id          : producto_so_id,
                producto_hml_id         : producto_id,
                req_desde               : nuevaFecha,
                req_cod_producto_hml    : cod_producto_hml,
                producto_uni_medida     : uni_medida
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.respuesta){
            notificacionServidor('success', data.message)
            dispatch(ObtenerHomologadosReducer())
        }else{
            notificacionServidor('error', data.message)
        }
        // dispatch(CreateAuditsReducer(1, 'ip', '', data, data.message, 'ACTUALIZAR HOMOLOGACIONES', '/approvals/upload-approved', null, null))
    }).catch((error)=> {
        console.log(error)
    })

    return true
}

export const ActualizarFiltroInputHomologados = ( column, value ) => async ( dispatch, getState ) => {

    let filtro_input = getState().homologados.rex_filtro_input_data_homologados

    filtro_input = {...filtro_input, [column] : value}

    await dispatch({
        type: FILTRO_INPUT_DATA_HOMOLOGADOS,
        payload: filtro_input
    })

    return true
}

export const ActualizarFiltroOrdenHomologados = (column, order) => async ( dispatch ) => {

    const filtro_orden = { column : column, orden: order}

    dispatch({
        type: FILTRO_ORDEN_DATA_HOMOLOGADOS,
        payload: filtro_orden
    })

    let orden_index = column == 'key' ? true : false
    dispatch(ObtenerHomologadosReducer(orden_index))

    return true
}

export const ActualizarPaginaTablaHomologados = (page) => async ( dispatch ) => {

    dispatch({
        type: PAGINA_TABLA_DATA_HOMOLOGADOS,
        payload: page
    })
    
    dispatch(ObtenerHomologadosReducer(true))
    
    return true
}

export const MostrarOtrosHomologados = (value) => async ( dispatch) => {
    
    dispatch({
        type: MOSTRAR_OTROS_HOMOLOGADOS,
        payload: value
    })
    dispatch(ObtenerHomologadosReducer(true))
}

export const ClearDataHomologadosReducer = () => async ( dispatch) => {
    dispatch({
        type: OBTENER_DATA_HOMOLOGADOS,
        payload: []
    })
    dispatch({
        type: OBTENER_DATA_CANTIDAD_HOMOLOGADOS,
        payload: null
    })
    dispatch({
        type: FILTRO_TIPOS_CONEXIONES_HOMOLOGADOS,
        payload: []
    })
}