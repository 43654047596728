import config from '../../../../config'
import {
    GET_DATA_ASSIGNMENT_PRODUCT_MASSIVE,
    LOADING_DATA_ASSIGNMENT_PRODUCT_MASSIVE,
    SELECTED_PRODUCT,
    UN_MIN_PRODUCT_MASSIVE,
} from '../../../../Constans/Administracion/AssignmentProductMassive/AssignmentProductMassive'
import { notification } from 'antd'

const STATUS_ERR = 400
const notificacionServidor = (type, mensaje, title=null) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const GetDataAssignmentProductMassive = (cod_material) => async (dispatch, getState) => {
    dispatch({
        type: LOADING_DATA_ASSIGNMENT_PRODUCT_MASSIVE,
        payload: true
    })

    await fetch(config.api_url+'administration/search-product-assign-masive',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_cod_material: cod_material,
            })
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            dispatch({
                type: GET_DATA_ASSIGNMENT_PRODUCT_MASSIVE,
                payload: data.data
            })
        }else{
            if(data.status == STATUS_ERR){
                notificacionServidor('warning', data.message)
            }else{
                notificacionServidor('error', data.message)
            }
        }
    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type: LOADING_DATA_ASSIGNMENT_PRODUCT_MASSIVE,
        payload: false
    })
}

export const GetProductAssignmentProductMassive = () => async (dispatch, getState) => {
    await fetch(config.api_url+'administration/get-unique-product-assign-masive',
            {
                mode:'cors',
                method: 'POST',
                headers: {
                    'Accept' : 'application/json',
                    'Content-type' : 'application/json',
                    'usutoken'	   : localStorage.getItem("usutoken"),
                },
            }
        )
        .then(res => res.json())
        .then(data => {
            if(data.response){
                if(data.data){
                    dispatch({
                        type: SELECTED_PRODUCT,
                        payload: { 
                            codigo_material: data.data.codigo_material, 
                            material: data.data.material,
                        }
                    })

                    dispatch({
                        type: UN_MIN_PRODUCT_MASSIVE,
                        payload: data.data.mpgunmin
                    })
                }
            }
        }).catch((error)=> {
            console.log(error)
        })
}

export const UpdateAssignmentProductMassive = () => async (dispatch, getState) => {
    const { 
        rex_selected_product,
        rex_un_min_product_massive,
    } = getState().assignmentProductMassive

    if(rex_selected_product.codigo_material){
        await fetch(config.api_url+'administration/update-product-assign-masive',
            {
                mode:'cors',
                method: 'POST',
                headers: {
                    'Accept' : 'application/json',
                    'Content-type' : 'application/json',
                    'usutoken'	   : localStorage.getItem("usutoken"),
                },
                body: JSON.stringify({
                    req_cod_material: rex_selected_product.codigo_material,
                    req_un_min: rex_un_min_product_massive
                })
            }
        )
        .then(res => res.json())
        .then(data => {
            if(data.response){
                notificacionServidor('success', data.message)
            }else{
                if(data.status == STATUS_ERR){
                    notificacionServidor('warning', data.message)
                }else{
                    notificacionServidor('error', data.message)
                }
            }
        }).catch((error)=> {
            console.log(error)
        })
    }else{
        notificacionServidor('warning', "Por favor seleccionar un producto")
    }

    return true
}

export const ChangeUnMinAssignmentProductMassive = (value) => async (dispatch, getState) => {
    dispatch({
        type: UN_MIN_PRODUCT_MASSIVE,
        payload: value
    })
}

export const LoadingAssignmentProductMassive = () => async (dispatch, getState) => {
    dispatch({
        type: LOADING_DATA_ASSIGNMENT_PRODUCT_MASSIVE,
        payload: true
    })
}

export const SelectedProductAssignmentProductMassive = (values) => async (dispatch, getState) => {
    dispatch({
        type: SELECTED_PRODUCT,
        payload: values
    })
}