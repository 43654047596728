import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Typography, Table, Card, Input, Button, Pagination, DatePicker, Skeleton, Select } from 'antd'
import {
    FilterOutlined,
    ReloadOutlined,
    ArrowLeftOutlined,
    CaretDownOutlined,
    CaretUpOutlined,
    CalendarOutlined,
    EyeOutlined,
    EyeInvisibleOutlined
} from '@ant-design/icons'
import moment from 'moment'
import { format } from 'date-fns'
import { 
    ObtenerMasterProductosReducer,
    SelectProductsNoHomologacionReducer
} from '../../../Redux/Actions/Homologaciones/NoHomologados/NoHomologados'
import IconPaquete from '../../../Assets/Iconos/paquete.png'
import { 
    ObtenerHomologadosReducer,
    ActualizarPaginaTablaHomologados,
    ActualizarFiltroOrdenHomologados,
    ActualizarFiltroInputHomologados,
    MostrarOtrosHomologados
} from '../../../Redux/Actions/Homologaciones/Homologados/Homologados'
import { ClearDataHomologadosNoHomologados } from '../../../Redux/Actions/Homologaciones/NoHomologados/AsignarNoHomologados'
import { funPermisosObtenidos, funPermisosObtenidosBoolean } from '../../../Functions/funPermiso'
import '../../../Styles/Routes/Homologaciones/Homologados/Homologados.css'

const Homologados = (props) => {
    const collapsed = props.collapsed
    const { Title } = Typography

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [ firstRender, setFirstRender ] = useState(false)

    const {
        rex_data_user_permissions
    } = useSelector(({login}) => login)

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={rex_filtro_input_data_homologados[dataIndex]}
                    disabled={rex_cargando_data_homologados? true : false}
                    onChange={(e) => {
                        confirm({
                            closeDropdown: false,
                        })
                        setFirstRender(true)
                        dispatch(ActualizarFiltroInputHomologados( dataIndex, e.target.value))
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: (filtered) => (
            <FilterOutlined
                style={{color:rex_filtro_input_data_homologados[dataIndex]?.length > 0 ? 'blue':'gray'}}
            />
        ),
    })

    const getColumnDateProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{ padding: 8 }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <DatePicker
                    disabled={rex_cargando_data_homologados? true : false}
                    allowClear={true}
                    onChange={(value)=> {
                        setFirstRender(true)
                        dispatch(ActualizarFiltroInputHomologados( dataIndex, value))
                    }}
                />
            </div>
        ),
        filterIcon: () => (
            <CalendarOutlined
                style={{color: rex_filtro_input_data_homologados[dataIndex] != null ?  'blue' : 'gray'}}
            />
        ),
    })

    const {
        rex_data_master_productos,
    } = useSelector(({noHomologados}) => noHomologados)

    const titleColumn = ( columnName, column ) => (
        <div
            className='Container-Title-Column-Approvals'
            onClick={() => {
                let orden
                if(rex_filtro_orden_data_homologados.orden == 'asc'){
                    orden = 'desc'
                }else if(rex_filtro_orden_data_homologados.orden == 'desc'){
                    orden = null
                }else{
                    orden = 'asc'
                }
                dispatch(ActualizarFiltroOrdenHomologados(columnName, orden))
            }}
        >
            {
                columnName == 'index'
                ? <div></div>
                :null
            }
            <span title={column}>{column}</span>
            <div>
                <CaretUpOutlined
                    className={`${rex_filtro_orden_data_homologados.orden == 'asc' && rex_filtro_orden_data_homologados.column == columnName ? 'Color-Icon-Blue' : 'Color-Icon-Gray'}`}/>
                <CaretDownOutlined 
                    className={`${rex_filtro_orden_data_homologados.orden == 'desc' && rex_filtro_orden_data_homologados.column == columnName ? 'Color-Icon-Blue' : 'Color-Icon-Gray'}`}/>
            </div>
        </div>
    )

    const {
        rex_data_homologados,
        rex_data_cantidad_homologados,
        rex_cargando_data_homologados,
        rex_filtro_orden_data_homologados,
        rex_filtro_input_data_homologados,
        rex_pagina_tabla_data_homologados,
        rex_mostrar_otros_homologados,
        rex_filtro_conexiones_homologados
    } = useSelector(({homologados}) => homologados)
    
    const ObtenerHomologados = async () => {
        await dispatch(ObtenerHomologadosReducer(true))
    }

    useEffect(() => {

        if(firstRender){
            const timeOutInactividad = setTimeout(() => {
                ObtenerHomologados()
            }, 1000);
    
            return () => clearTimeout(timeOutInactividad);
        }
    },[rex_filtro_input_data_homologados])

    const columnActions = {
        title: <span title="ACCIONES">ACCIONES</span>,
        dataIndex: 'actions',
        className: 'Tabla-Actions-Home',
        render: (data, info) => {
            const valueCombo = info.combo ? "combo" : "no-combo"
            return(
            <>
                <div style={{display:'flex', alignItems:'center', justifyContent: 'center'}}>
                {
                    funPermisosObtenidos(
                        rex_data_user_permissions, 'modulo.skuhomologado.editarcombo',
                        <Link to={`/asignar-homologados/${info.id}/${valueCombo}`}>
                            <img
                                src={IconPaquete}
                                style={{
                                    width: '20px'
                                }}
                                onClick={() => dispatch(SelectProductsNoHomologacionReducer(info))}
                            />
                        </Link>
                    )
                }
                </div>
            </>)
        },
        className:'Column-Actions-Homologados',
        fixed: 'right',
        width: '60px'
    }

    const columnUpdateDate = {
        title: titleColumn('fecha_homologado', 'FEC. HML.'),
        dataIndex: 'fecha_homologado',
        ...getColumnDateProps('fecha_homologado', 'fecha homologado'),
        render: (_,record) =>  
                    <div title={record.fecha_homologado ? moment(record.fecha_homologado).format('DD/MM/YYYY h:mm a') : ''}>
                        {record.fecha_homologado ? moment(record.fecha_homologado).format('DD/MM/YYYY h:mm a') : ''}
                    </div>,
        width: '130px',
        align:'center'
    }

    const columnPersonUpdate = {
        title: titleColumn('usuusuario', 'USUARIO'),
        dataIndex: 'usuusuario',
        ...getColumnSearchProps('usuusuario', 'usuario'),
        render: (_, record) => <div title={record.usuusuarios?record.usuusuarios.usuusuario: '' }>
                                    {record.usuusuarios?record.usuusuarios.usuusuario: ''}
                                </div>,
        width: '150px'
    }

    const columns = [
        {
            title: titleColumn('key', '#'),
            dataIndex: 'key',
            render: (_,record) => 
                <div title={record.key ? record.key: '-'}>
                    {record.key ? record.key :'-'}
                </div>,
            width: '50px',
            align:'center'
        },
        {
            title: titleColumn('attr_1', 'ATTR 1'),
            dataIndex: 'attr_1',
            ...getColumnSearchProps('attr_1', 'attr_1'),
            render: (_, record) => 
            <div 
                title={record.masterclientes_grow?.attr_1 ? record.masterclientes_grow?.attr_1 : '-'}
            >
                {record.masterclientes_grow?.attr_1 ? record.masterclientes_grow?.attr_1 : '-'}
            </div>,
            width: '100px'
        },
        {
            title: titleColumn('zone', 'ZONE'),
            dataIndex: 'zone',
            ...getColumnSearchProps('zone', 'zone'),
            render: (_,record) => 
                <div title={record.masterclientes_grow ? record.masterclientes_grow.zone: '-'}>
                    {record.masterclientes_grow ? record.masterclientes_grow.zone :'-'}
                </div>,
            width: '75px',
        },
        {
            title: titleColumn('regional_gba', 'REGIONAL GBA'),
            dataIndex: 'regional_gba',
            ...getColumnSearchProps('regional_gba', 'región'),
            render: (_,record) => <div title={record.masterclientes_grow ? record.masterclientes_grow.regional_gba: '-'}>{record.masterclientes_grow ? record.masterclientes_grow.regional_gba :'-'}</div>,
            width: '100px',
        },
        {
            title: titleColumn('cliente_hml', 'Cliente HML'),
            dataIndex: 'cliente_hml',
            ...getColumnSearchProps('cliente_hml', 'cliente hml'),
            render: (_,record) => 
                        <div title={record.masterclientes_grow ? record.masterclientes_grow.cliente_hml: '-'}>
                            {record.masterclientes_grow ? record.masterclientes_grow.cliente_hml :'-'}
                        </div>,
            width: '110px',
        },
        {
            title: titleColumn('client_sub_hml', 'Sucursal HML'),
            dataIndex: 'client_sub_hml',
            ...getColumnSearchProps('client_sub_hml', 'sucursal hml'),
            render: (_,record) => 
                        <div title={record.masterclientes_grow ? record.masterclientes_grow.client_sub_hml: '-'}>
                            {record.masterclientes_grow ? record.masterclientes_grow.client_sub_hml :'-'}
                        </div>,
            width: '130px',
        },
        {
            title: titleColumn('codigo_destinatario', 'COD PROD NO HML'),
            dataIndex: 'codigo_destinatario',
            ...getColumnSearchProps('codigo_destinatario', 'código producto no homologado'),
            render: (_, record) => <div style={{textAlign: 'end'}} title={ record.codigo_producto ? record.codigo_producto : '-'}>
                { record.codigo_producto ? record.codigo_producto : '-' }
                </div>,
            width: '100px'
        },
        {
            title: titleColumn('descripcion_producto', 'NOM PROD DT'),
            dataIndex: 'descripcion_producto',
            ...getColumnSearchProps('descripcion_producto', 'producto no homologado'),
            render: (descripcion_producto) => <div title={descripcion_producto}>{descripcion_producto}</div>,
            width: '150px'
        },
        {
            title: titleColumn('codigo_material', 'COD PROD HML'),
            dataIndex: 'master_productos_grow',
            ...getColumnSearchProps('codigo_material', 'código producto homologado'),
            render: (master_productos_grow) => <div style={{textAlign: 'end'}} title={master_productos_grow.codigo_material}>{master_productos_grow.codigo_material}</div>,
            width: '100px'
        },
        {
            title: titleColumn('material', 'PROD HML'),
            dataIndex: 'master_productos_grow',
            ...getColumnSearchProps('material', 'producto homologado'),
            render: (master_productos_grow) => <div title={master_productos_grow.material}>{master_productos_grow.material}</div>,
            width: '100px'
        },
    ]

    const ObtenerDataHomologados = async () => {
        if(rex_data_master_productos.length == 0){
            await dispatch(ObtenerMasterProductosReducer())
        }
        if(rex_data_homologados.length == 0){
            await dispatch(ObtenerHomologadosReducer(true))
        }
        await dispatch(ClearDataHomologadosNoHomologados())        
    }

    useEffect(() => {
        ObtenerDataHomologados()
    }, [])

    return (
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Button 
                                type='primary' 
                                size='small' 
                                ghost 
                                onClick={() => navigate(-1)} className="Button-Title-Module"
                            >
                                <ArrowLeftOutlined />
                            </Button>
                        <Title className="Title-Module">SKU (Homologado)</Title>

                        </div>
                    }
                    className="Card-Item"
                >
                    <div className='Container-Action-Info-Table'>
                        <div style={{display:'flex'}}>
                            <Button
                                style={{display:'flex', alignItems:'center', marginRight:'10px'}} size='small'
                                onClick={async ()=> {
                                    await dispatch(ObtenerMasterProductosReducer())
                                    await dispatch(ObtenerHomologadosReducer(true))
                                }}     
                            >
                                <ReloadOutlined
                                    className='Button-Action-Reload'
                                />Recargar
                            </Button>
                            {/* <Button
                                onClick={() => dispatch(MostrarOtrosHomologados(!rex_mostrar_otros_homologados))}
                                style={{display:'flex', alignItems:'center', marginRight:'10px'}} size='small'
                            >
                               {rex_mostrar_otros_homologados ? <EyeOutlined /> : <EyeInvisibleOutlined />} Otros
                            </Button> */}
                            <Select
                                    showSearch
                                    allowClear
                                    placeholder="Filtro por attr 1"
                                    onChange={(e) => {
                                        let value = e == undefined ? '' : e
                                        dispatch(ActualizarFiltroInputHomologados('attr_1',value))
                                        dispatch(ObtenerHomologadosReducer(true))
                                    }}
                                    value={
                                        rex_filtro_input_data_homologados.attr_1
                                        ? rex_filtro_input_data_homologados.attr_1
                                        : null
                                    }
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    size='small'
                                    style={{width: '200px'}}
                                    options={rex_filtro_conexiones_homologados}
                                />
                        </div>
                        { rex_cargando_data_homologados && rex_data_homologados.length == 0
                            ? <Skeleton.Input active={true} size='default'/>
                            : <div style={{display:'flex', columnGap: '10px', alignItems: 'center'}}>
                                <Pagination
                                    current={rex_pagina_tabla_data_homologados}
                                    total={rex_data_cantidad_homologados}
                                    pageSize={20}
                                    onChange={async (page, pageSize) => {
                                        dispatch(ActualizarPaginaTablaHomologados(page))
                                    }}
                                    size='small'
                                />
                                <div>Total: {Intl.NumberFormat('en-IN').format(rex_data_cantidad_homologados)} registros</div>
                            </div>
                        }
                        
                    </div>

                    { rex_cargando_data_homologados && rex_data_homologados.length == 0
                    ? <div style={{marginTop:'10px'}}>
                        <Skeleton/>
                        <Skeleton/>
                    </div>
                    : <>
                        <Table
                            pagination={{
                                position: ["none", "none"],
                                pageSize: 20
                            }}
                            scroll={{
                                y: '450px',
                                x:'500px'
                            }}
                            columns={
                                funPermisosObtenidosBoolean(rex_data_user_permissions,'modulo.skuhomologado.visualizarfechaactualizacion')
                                ? [...columns, columnUpdateDate, columnPersonUpdate, columnActions]
                                : [ ...columns, columnActions]
                            }
                            loading={rex_cargando_data_homologados}
                            dataSource={rex_data_homologados} 
                            className="Table-Home"
                            size='small'
                        />
                    </>
                    }
                </Card>
            </Card>
        </div>
    )
}

export default Homologados