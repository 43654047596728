import {
    GET_UPLOAD_DATA_RESTRICTION,
    DATA_UPLOAD_DATA_RESTRICTION,
    DATA_UPDATE_UPLOAD_DATA_RESTRICTION,
    OPEN_MODAL_UPLOAD_DATA_RESTRICTION,
} from "../../../../Constans/Administracion/Administrador/UploadDataRestriction"

const INIT_STATE = {
    rex_get_upload_data_restriction            : [],
    rex_data_upload_data_restriction           : {},
    rex_data_update_upload_data_restriction    : {
        archivoplanoso: null,
        sellout: null,
        sellinthanos: null,
        masterproductos: null,
        masterclientes: null,
        masterprecios: null,
    },
    rex_open_modal_upload_data_restriction     : false,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_UPLOAD_DATA_RESTRICTION: {
            return {
                ...state,
                rex_get_upload_data_restriction : action.payload
            }
        }
        case DATA_UPLOAD_DATA_RESTRICTION: {
            return {
                ...state,
                rex_data_upload_data_restriction : action.payload
            }
        }
        case DATA_UPDATE_UPLOAD_DATA_RESTRICTION: {
            return {
                ...state,
                rex_data_update_upload_data_restriction : action.payload
            }
        }
        case OPEN_MODAL_UPLOAD_DATA_RESTRICTION: {
            return {
                ...state,
                rex_open_modal_upload_data_restriction : action.payload
            }
        }
        default:
            return state
    }
}
