import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { 
    ChangeDataUnitMeasureReducer, 
    ClearDataUnitMeasureReducer, 
    CreateUnitsMeasuresReducer, 
    GetUniqueDataUnitsMeasuresReducer, 
    UpdateUnitsMeasuresReducer 
} from '../../../Redux/Actions/Administracion/UnitsMeasures/UnitsMeasures'
import { Typography, Card, Input, Button, Form, Col, Row } from 'antd'
import {
    LoadingOutlined,
    SaveOutlined,
    ArrowLeftOutlined
} from '@ant-design/icons'
import '../../../Styles/Components/Administracion/Administrador/TiposUsuarios.css'

const CreateUpdateUnitsMeasures = (props) => {
    const collapsed = props.collapsed
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const { Title } = Typography

    const route_update = "/actualizar-unidades-de-medidas"
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { pathname, state } = useLocation()

    const {
        rex_unique_units_measures
    } = useSelector(({unitsMeasures}) => unitsMeasures)

    const createUpdateUnitsMeasures = async () => {
        let response = true
        setLoading(true)
        if(pathname == route_update){
            response = await dispatch(UpdateUnitsMeasuresReducer())
        }else{
            response = await dispatch(CreateUnitsMeasuresReducer())
        }

        setLoading(false)
        if(response){
            await clearCreateUpdateUnitMeasure()
            await ClearDataUnitMeasureReducer()
            navigate("/unidades-de-medidas")
        }
    }

    const clearCreateUpdateUnitMeasure = async () => {
        form.setFieldsValue({
            cod_un: '',
            un_med: '',
        })
    }

    const getUniqueDataUnitMeasureReducer = async () => {
        if(state.umdid){
            const {
                response,
                data_unique_unit_measure,
            } = await dispatch(GetUniqueDataUnitsMeasuresReducer(state.umdid))

            if(response){
                form.setFieldsValue({
                    umdid: data_unique_unit_measure.umdid,
                    cod_un: data_unique_unit_measure.cod_un,
                    un_med: data_unique_unit_measure.un_med,
                })
            }else{
                navigate("/unidades-de-medidas")
            }
        }else{
            navigate("/unidades-de-medidas")
        }
    }

    useEffect(() => {
        if(pathname == route_update){
            getUniqueDataUnitMeasureReducer()
        }else{
            clearCreateUpdateUnitMeasure()
            ClearDataUnitMeasureReducer()
        }
    }, [])

    return (
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s',
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Button 
                                type='primary' 
                                ghost 
                                size='small' 
                                onClick={() => navigate('/unidades-de-medidas')} className="Button-Title-Module"
                            ><ArrowLeftOutlined /></Button>
                            <Title className="Title-Module"> { pathname == route_update ? "Actualizar" : "Crear"} unidad de medida</Title>
                        </div>
                    }
                    className="Card-Item"
                >
                    <Form
                        form={form}
                        layout='vertical'
                        initialValues={{
                            cod_un: rex_unique_units_measures.cod_un ? rex_unique_units_measures.cod_un : '',
                            un_med: rex_unique_units_measures.un_med ? rex_unique_units_measures.un_med : '',
                        }}
                        onFinish={() => createUpdateUnitsMeasures()}
                    >
                        <Row gutter={16} style={{marginBottom: '5px'}}>
                            <Col span={12}>
                                <Form.Item
                                    rules={[
                                        { required: true, message: 'El cod un es un campo obligatorio' },
                                    ]}
                                    label='Cod Un:'
                                    name='cod_un'
                                    className="Type-Profile-Item"
                                >
                                    <Input
                                        onChange={(e) => dispatch(ChangeDataUnitMeasureReducer('cod_un', e.target.value))}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    rules={[
                                        { required: true, message: 'El un med es un campo obligatorio' },
                                    ]}
                                    label='Un Med:'
                                    name='un_med'
                                    className="Type-Profile-Item"
                                >
                                    <Input
                                        onChange={(e) => dispatch(ChangeDataUnitMeasureReducer('un_med', e.target.value))}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item className='Container-Button-Type-User'>
                            <Button 
                                danger
                                className='Button-Action-Type-User'
                                onClick={() => navigate('/unidades-de-medidas')}
                            >
                                Cancelar
                            </Button>
                            <Button 
                                type="primary" 
                                ghost
                                htmlType="submit"
                                className='Button-Action-Type-User'
                            >
                                {
                                    loading
                                    ? <LoadingOutlined />
                                    :<><SaveOutlined style={{marginRight:'6px'}}/>
                                        Guardar</>
                                }
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Card>
        </div>
    )
}

export default CreateUpdateUnitsMeasures