import config from '../../../../config'
import { notification } from 'antd'
import { 
    OBTENER_DATA_PERMISO,
    OBTENER_DATA_TIPOS_PERMISOS,
    OBTENER_DATA_PERMISOS 
} from '../../../../Constans/Administracion/Administrador/Permisos'


const notificacionServidor = (type, mensaje, title=null) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: title?title:'Error',
            description: <div dangerouslySetInnerHTML={{ __html: mensaje }} />,
            duration: 10
        })
    }
}


export const ObtenerDataPermisosReducer = () => async ( dispatch ) => {

    await fetch(config.api_url+'manage/get-permissions',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then( async res => {return res.json()})
    .then(data => {                
        if(data.response){
            console.log(data)
            dispatch({
                type: OBTENER_DATA_PERMISOS,
                payload: data.data.pems
            })
            dispatch({
                type: OBTENER_DATA_TIPOS_PERMISOS,
                payload: data.data.tpes
            })
        }
    }).catch((error)=> {
        console.log(error)
    })
}

export const ObtenerDataPermisoReducer = (pemid) => async ( dispatch ) => {

    await fetch(config.api_url+'manage/get-permission',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_pemid: parseInt(pemid)
            }),
        }
    )
    .then( async res => {return res.json()})
    .then(data => {                

        dispatch({
            type: OBTENER_DATA_PERMISO,
            payload: data.data
        })
    }).catch((error)=> {
        console.log(error)
    })
}

export const EliminarPermisoReducer = (pemid) => async ( dispatch ) => {

    await fetch(config.api_url+'manage/delete-permission',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_pemid: parseInt(pemid)
            }),
        }
    )
    .then( async res => {return res.json()})
    .then(data => {                

        if(data.response){
            dispatch(ObtenerDataPermisosReducer())
            notificacionServidor('success', data.message)
        }else{
            notificacionServidor('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })
}

export const EditarPermisoReducer = (pemid) => async ( dispatch ) => {

    await fetch(config.api_url+'manage/edit-permission',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_pemid       : '',
                req_pemnombre   : '',
                req_tpeid       : '',
                req_pemslug     : '',
                req_pemruta     : '',
                req_pemespecial : '',
            }),
        }
    )
    .then( async res => {return res.json()})
    .then(data => {                
        if(data.response){
            dispatch(ObtenerDataPermisosReducer())
            notificacionServidor('success', data.message)
        }else{
            notificacionServidor('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })
}