import React, {useState, useEffect} from 'react'
import { Typography, Table, Card, Button, Popconfirm, Input, Pagination, Skeleton } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    ReloadOutlined,
    ArrowLeftOutlined,
    FilterOutlined,
} from '@ant-design/icons'
import { 
    EliminarTipoUsuarioReducer,
    ObtenerDataTiposUsuariosReducer 
} from '../../../Redux/Actions/Administracion/Administrador/TiposUsuarios'
import '../../../Styles/Components/Administracion/Administrador/TiposUsuarios.css'

const TiposUsuarios = (props) => {
    
    const collapsed = props.collapsed
    const { Title } = Typography
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [ pageTable, setPageTable ] = useState(1)

    const {
        rex_data_tipos_usuarios,
        rex_cargando_data_tipos_usuarios
    } = useSelector(({tiposUsuarios}) => tiposUsuarios)

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined style={{color:'white'}}/>),
        onFilter: (value, record) => record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
    })

    const obtenerTiposUsuarios = async () => {
        await dispatch(ObtenerDataTiposUsuariosReducer())
    }

    useEffect(() => {
        if(rex_data_tipos_usuarios.length == 0){
            obtenerTiposUsuarios()
        }
    },[])

    const columns = [
        {
            title: 'N°',
            dataIndex: 'index',
            sorter: (a, b) => a.index ? a.index - b.index : false,
            showSorterTooltip: false,
            render: (_, record) => <div>{record.index}</div>,
            width: '20px',
            align:'center'
        },
        {
            title: 'Estado',
            dataIndex: 'estid',
            sorter: (a, b) => a.estid ? a.estid - b.estid : false,
            showSorterTooltip: false,
            render: (estid) => <div style={{display:'flex', justifyContent:'center'}}>
                <div title={estid == 1 ? 'Activado' : 'Desactivado'} className={estid == 1 ? 'Status-Color-Green' : 'Status-Color-Red'}></div>
            </div>,
            width: '50px',
            align:'center'
        },
        {
            title: 'Tipo de usuario',
            dataIndex: 'tpunombre',
            ...getColumnSearchProps('tpunombre', 'tipo de usuario'),
            sorter: (a, b) => a.tpunombre? a.tpunombre.localeCompare(b.tpunombre) : false,
            showSorterTooltip: false,
            render: (tpunombre) => <div title={tpunombre}>{tpunombre}</div>,
            width: '100px',
            align:'center'
        },
        {
            title: 'Acciones',
            dataIndex: '',
            render: (_, record) => <div>
                <EditOutlined 
                    title='Editar'
                    onClick={() => {
                        navigate(`/admin/editar-tipo-usuario/${record.tpuid}`)
                    }}
                    style={{fontSize:'15px', color:'blue', cursor:'pointer', margin:'0 2px'}}
                />
                <Popconfirm
                    title='Eliminar tipo de usuario'
                    description='Está seguro de eliminar al tipo de usuario?'
                    okText='Confirmar'
                    cancelText='Cancelar'
                    placement="bottomRight"
                    onConfirm={() => dispatch(EliminarTipoUsuarioReducer(record.tpuid))}
                >
                    <DeleteOutlined 
                        style={{fontSize:'15px', color:'red', cursor:'pointer', margin:'0 2px'}}
                    />
                </Popconfirm>
            </div>,
            width: '50px',
            align:'center'
        }
    ]

    return (
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Button type='primary' size='small' ghost onClick={() => navigate(-1)} className="Button-Title-Module">
                                <ArrowLeftOutlined />
                            </Button>
                            <Title className="Title-Module">Tipos de usuarios</Title>
                        </div>
                    }
                    className="Card-Item"
                >
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        <div style={{display:'flex'}}>
                            <Button
                                className='Button-Extra-Option-Type-User' size='small'
                                onClick={()=> {
                                    obtenerTiposUsuarios()
                                }}    
                            >
                                <ReloadOutlined
                                    className='Icon-Button-Extra-Option-Type-User'
                                />Recargar
                            </Button>
                            <Button
                                className='Button-Extra-Option-Type-User' size='small'
                                onClick={()=> {
                                    navigate(`/admin/crear-tipo-usuario`)
                                }}    
                            >
                                <PlusOutlined
                                    className='Icon-Button-Extra-Option-Type-User'
                                />Nuevo
                            </Button>
                        </div>
                        { rex_cargando_data_tipos_usuarios && rex_data_tipos_usuarios.length == 0
                            ? <Skeleton.Input active={true} size='default'/>
                            : <div style={{display:'flex', columnGap: '10px', alignItems: 'center'}}>
                                <Pagination
                                    current={pageTable}
                                    total={rex_data_tipos_usuarios.length}
                                    pageSize={15}
                                    onChange={(page, pageSize) => setPageTable(page)}
                                    size='small'
                                />
                                <div>Total: {Intl.NumberFormat('en-IN').format(rex_data_tipos_usuarios.length)} tipos de usuarios</div>
                            </div>
                        }
                        
                    </div>
                    { rex_cargando_data_tipos_usuarios && rex_data_tipos_usuarios.length == 0
                    ? <div style={{marginTop:'10px'}}>
                        <Skeleton/>
                        <Skeleton/>
                    </div>
                    :   <Table
                            pagination={{
                                position: ["none", "none"],
                                pageSize: 15,
                                current: pageTable,
                            }}
                            columns={columns}
                            dataSource={rex_data_tipos_usuarios}
                            className="Table-Home Table-Tipos-Usuarios"
                            size='small'
                        />
                    }
                </Card>
            </Card>
        </div>
    )
}

export default TiposUsuarios