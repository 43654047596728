import { 
    OBTENER_DATA_LISTA_PRECIOS
} from "../../../../Constans/Administracion/ListaPrecios/ListaPrecios";


const INIT_STATE = {
    rex_data_lista_precios : []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case OBTENER_DATA_LISTA_PRECIOS : {
            return {
                ...state,
                rex_data_lista_precios : action.payload
            }
        }
        default:
            return state;
    }
}
