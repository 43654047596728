import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Table, Card, Button } from 'antd'
import { ObtenerDataPermisosReducer } from '../../../Redux/Actions/Administracion/Administrador/Permisos'
import {
    DeleteOutlined,
    EditOutlined,
    ReloadOutlined,
    PlusOutlined
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const Permisos = (props) => {

    const collapsed = props.collapsed
    const { Title } = Typography

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const ObtenerPermisos = async () => {
        await dispatch(ObtenerDataPermisosReducer())
    }

    const {
        rex_data_permisos
    } = useSelector(({permisos}) => permisos)

    useEffect(() => {
        ObtenerPermisos()
    },[])

    const columns = [
        {
            title: 'N°',
            dataIndex: 'estid',
            render: (id, record, index) => { ++index; return index; },
            width: '20px',
            align:'center'
        },
        {
            title: 'Permiso',
            dataIndex: 'pemnombre',
            render: (pemnombre) => <div title={pemnombre}>{pemnombre}</div>,
            width: '100px',
            align:'center'

        },
        {
            title: 'Slug',
            dataIndex: 'pemslug',
            render: (pemslug) => <div title={pemslug}>{pemslug}</div>,
            width: '100px',
            align:'center'

        },
        {
            title: 'Ruta',
            dataIndex: 'pemruta',
            render: (pemruta) => <div title={pemruta}>{pemruta}</div>,
            width: '100px',
            align:'center'

        },
        {
            title: 'Tipo de permiso',
            dataIndex: 'tpenombre',
            render: (_, record) => <div title={record.tpetipospermisos.tpenombre}>{record.tpetipospermisos.tpenombre}</div>,
            width: '100px',
            align:'center'

        },
        {
            title: 'Acciones',
            dataIndex: '',
            render: (_, record) => <div>
                <EditOutlined 
                    title='Editar'
                    onClick={() => {
                        navigate(`/admin/editar-permiso/${record.pemid}`)
                    }}
                    style={{fontSize:'15px', color:'blue', cursor:'pointer', margin:'0 2px'}}
                />
                <DeleteOutlined 
                    title='Eliminar'
                    style={{fontSize:'15px', color:'red', cursor:'pointer', margin:'0 2px'}}
                    // onClick={() => dispatch(EliminarTipoUsuarioReducer(record.tpuid))}
                />
            </div>,
            width: '50px',
            align:'center'
        }
    ]

    return (
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={<Title level={4} style={{marginBottom: 0}}>Permisos</Title>}
                    className="Card-Item"
                >
                    <div style={{display:'flex'}}>
                        <Button
                            className='Button-Extra-Option-Type-User' size='small'
                            // onClick={()=> {
                            //     obtenerTiposUsuarios()
                            // }}    
                        >
                            <ReloadOutlined
                                className='Icon-Button-Extra-Option-Type-User'
                            />Recargar
                        </Button>
                        <Button
                            className='Button-Extra-Option-Type-User' size='small'
                            // onClick={()=> {
                            //     navigate(`/admin/crear-tipo-usuario`)
                            // }}    
                        >
                            <PlusOutlined
                                className='Icon-Button-Extra-Option-Type-User'
                            />Nuevo
                        </Button>
                    </div>
                    <Table
                        // loading={cargandoTiposUsuarios}
                        pagination={{
                            position: ["topRight", "none"],
                        }}
                        columns={columns}
                        dataSource={rex_data_permisos}
                        className="Table-Home Table-Tipos-Usuarios"
                        size='small'
                    />
                </Card>
            </Card>
        </div>
    )
}

export default Permisos