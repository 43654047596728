import React from 'react'
import { Typography, Table, Card } from 'antd'
import {
    SearchOutlined,
} from '@ant-design/icons'
import '../../Styles/Routes/Home/Home.css'

const Home = (props) => {
    const collapsed = props.collapsed
    const { Title } = Typography

    const data = [
        {
            key: '1',
            name: 'John Brown',
            age: 32,
            address: 'New York No. 1 Lake Park',
            dato1: 'dato 1 cumque veniam tempore sit deleniti provident impedit e',
            dato2: 'data 2 deleniti provident impedit odit magnam facere',
        },
        {
            key: '2',
            name: 'Joe Black',
            age: 42,
            address: 'London No. 1 Lake Park',
            dato1: 'dato 1 veniam tempore sit deleniti provident impedit odit magnam facere',
            dato2: 'cumque veniam tempore sit deleniti data2a 2 impedit odit magnam facere',
        },
        {
            key: '3',
            name: 'Jim Green',
            age: 32,
            address: 'Sydney No. 1 Lake Park',
            dato1: 'cumque veniam tempore sit delenitiddd antd provident impedit odit magnam facere',
            dato2: 'cumque veniam antdprimero tempore sit deleniti provident impedit odit magnam facere',
        },
        {
            key: '4',
            name: 'Jim Red',
            age: 32,
            address: 'London No. 2 Lake Park',
            dato1: 'cumque veniam tempore 31impedit odit magnam facere',
            dato2: 'cumque veniam tempore sit deleniti provident 999',
        },
    ]

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                {/* <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                /> */}
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    })


    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
            width: '100px'
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
            ...getColumnSearchProps('age'),
            width: '70px'
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            ...getColumnSearchProps('address'),
            width: '150px'
        },
        {
                title: 'Dato 1',
                dataIndex: 'dato1',
                key: 'dato1',
                ...getColumnSearchProps('dato1'),
                width: '250px'
        },
        {
            title: 'Dato 2',
            dataIndex: 'dato2',
            key: 'dato2',
            ...getColumnSearchProps('dato2'),
            width: '250px'
        }
    ]

    return (
        <div
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 200px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
        <Card className="Card-Container">
            <Card 
                title={<Title level={4} style={{marginBottom: 0}}>Tabla de Bienvenida</Title>}
                className="Card-Item"
            >
                <Table 
                    pagination={{
                        position: ["topRight", "none"],
                    }}
                    scroll={{
                        y: '330px',
                    }}
                    columns={columns} 
                    dataSource={data} 
                    className="Table-Home"
                    size='small'
                />
            </Card>
        </Card>
        </div>
    )
}

export default Home