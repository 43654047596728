import {
    OBTENER_DATA_DISTRIBUIDORAS,
    CARGANDO_DATA_DISTRIBUIDORAS,
    GET_DATA_CONTROL_DISTRIBUTORS,
} from "../../../../Constans/Administracion/Distribuidoras/Distribuidoras";

const INIT_STATE = {
    rex_data_distribuidoras : [],
    rex_cargando_data_distribuidoras : true,
    rex_data_control_distributors: [],
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case OBTENER_DATA_DISTRIBUIDORAS: {
            return {
                ...state,
                rex_data_distribuidoras : action.payload
            }
        }
        case CARGANDO_DATA_DISTRIBUIDORAS: {
            return {
                ...state,
                rex_cargando_data_distribuidoras : action.payload
            }
        }
        case GET_DATA_CONTROL_DISTRIBUTORS: {
            return {
                ...state,
                rex_data_control_distributors : action.payload
            }
        }
        default:
            return state
    }
}
