import config from '../../../../config'
import { notification } from 'antd'
import {
    GET_DATA_UNITS_MEASURES,
    LOADING_DATA_UNITS_MEASURES,
    GET_UNIQUE_UNITS_MEASURES,
} from '../../../../Constans/Administracion/UnitsMeasures/UnitsMeasures'

const STATUS_ERR = 400

const notificacionServidor = (type, mensaje, title=null) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const GetDataUnitsMeasuresReducer = () => async (dispatch, getState) => {

    dispatch({
        type: LOADING_DATA_UNITS_MEASURES,
        payload : true
    })

    await fetch(config.api_url+'administration/get-units-measures',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            const data_units_measures = data.data
            await data_units_measures.map((unit, index) => {
                data_units_measures[index]["index"] = index + 1
            })

            dispatch({
                type: GET_DATA_UNITS_MEASURES,
                payload: data_units_measures
            })
        }else{
            if(data.status == STATUS_ERR){
                notificacionServidor('warning', data.message)
            }else{
                notificacionServidor('error', data.message)
            }
        }
    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type: LOADING_DATA_UNITS_MEASURES,
        payload : false
    })
}

export const CreateUnitsMeasuresReducer = () => async (dispatch, getState) => {
    let response = true
    const { rex_unique_units_measures } = getState().unitsMeasures

    await fetch(config.api_url+'administration/create-units-measures',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_cod_un: rex_unique_units_measures.cod_un,
                req_un_med: rex_unique_units_measures.un_med,
            })
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            notificacionServidor('success', data.message)
        }else{
            response = data.response
            if(data.status == STATUS_ERR){
                notificacionServidor('warning', data.message)
            }else{
                notificacionServidor('error', data.message)
            }
        }
    }).catch((error)=> {
        console.log(error)
    })

    return response
}

export const ChangeDataUnitMeasureReducer = (type, value) => async (dispatch, getState) => {
    const { rex_unique_units_measures } = getState().unitsMeasures

    const data_unit_measure = {
        ...rex_unique_units_measures,
        [type]: value
    }

    dispatch({
        type: GET_UNIQUE_UNITS_MEASURES,
        payload : data_unit_measure
    })
}

export const GetUniqueDataUnitsMeasuresReducer = (req_umdid) => async (dispatch) => {
    let data_unique_unit_measure = {}
    let response = true
    await fetch(config.api_url+'administration/get-unique-units-measures',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_umdid: req_umdid,
            })
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            const data_obtained = data.data
            data_unique_unit_measure = {
                umdid: data_obtained.umdid,
                cod_un: data_obtained.umdcod_un,
                un_med: data_obtained.umdun_med,
            }

            dispatch({
                type: GET_UNIQUE_UNITS_MEASURES,
                payload : data_unique_unit_measure
            })
        }else{
            response = false
            if(data.status == STATUS_ERR){
                notificacionServidor('warning', data.message)
            }else{
                notificacionServidor('error', data.message)
            }
        }
    }).catch((error)=> {
        console.log(error)
    })

    return {
        response,
        data_unique_unit_measure,
    }
}

export const UpdateUnitsMeasuresReducer = () => async (dispatch, getState) => {
    let response = true
    const { rex_unique_units_measures } = getState().unitsMeasures

    await fetch(config.api_url+'administration/update-units-measures',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_umdid: rex_unique_units_measures.umdid,
                req_cod_un: rex_unique_units_measures.cod_un,
                req_un_med: rex_unique_units_measures.un_med,
            })
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            notificacionServidor('success', data.message)
        }else{
            response = false
            if(data.status == STATUS_ERR){
                notificacionServidor('warning', data.message)
            }else{
                notificacionServidor('error', data.message)
            }
        }
    }).catch((error)=> {
        console.log(error)
    })

    return response
}

export const DeleteUnitsMeasuresReducer = (umdid) => async () => {
    let response = true
    await fetch(config.api_url+'administration/delete-units-measures',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_umdid: umdid,
            })
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            notificacionServidor('success', data.message)
        }else{
            response = data.response
            if(data.status == STATUS_ERR){
                notificacionServidor('warning', data.message)
            }else{
                notificacionServidor('error', data.message)
            }
        }
    }).catch((error)=> {
        console.log(error)
    })

    return response
}

export const ClearDataUnitMeasureReducer = () => async (dispatch) => {
    dispatch({
        type: GET_UNIQUE_UNITS_MEASURES,
        payload : {}
    })
}