import { 
    GET_DATA_UNITS_MEASURES,
    LOADING_DATA_UNITS_MEASURES,
    GET_UNIQUE_UNITS_MEASURES,
} from "../../../../Constans/Administracion/UnitsMeasures/UnitsMeasures"

const INIT_STATE = {
    rex_data_units_measures           : [],
    rex_loading_data_units_measures   : false,
    rex_unique_units_measures         : {},
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DATA_UNITS_MEASURES: {
            return {
                ...state,
                rex_data_units_measures : action.payload
            }
        }
        case LOADING_DATA_UNITS_MEASURES: {
            return {
                ...state,
                rex_loading_data_units_measures : action.payload
            }
        }
        case GET_UNIQUE_UNITS_MEASURES: {
            return {
                ...state,
                rex_unique_units_measures : action.payload
            }
        }
        default:
            return state
    }
}