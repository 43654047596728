import config from '../../../config'
import { 
    DATA_USER_LOGIN,
    DATA_USER_PERMISSIONS
} from '../../../Constans/Login/Login'
import { 
    GET_PENDING_STATUS, LOADING_PENDING_STATUS 
} from '../../../Constans/Status/Status'
import moment from 'moment'
import { notification } from 'antd'

const notificacionServidor = (type, mensaje, title=null) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: title?title:'Error',
            description: <div dangerouslySetInnerHTML={{ __html: mensaje }} />,
            duration: 10
        })
    }
}

export const TokenUserReducer = (re_token, token) => async ( dispatch ) => {
    let val = false
    await fetch(config.api+'token-user',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                // 'token'        : token,
                // 'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_token: re_token
            }),
        }
    )
    .then(async res => await res.json())
    .then(async data => {
        if(data.respuesta){
            await localStorage.setItem('usutoken', data.user.usutoken)
            await localStorage.setItem('token_val', data.user.token_val)
            await localStorage.setItem('tpuprivilegio', data.user.tputiposusuarios.tpuprivilegio)
            await dispatch({
                type: DATA_USER_LOGIN,
                payload: data.user
            })
            await dispatch({
                type: DATA_USER_PERMISSIONS,
                payload: data.user.tputiposusuarios.tuptiposusuariospermisos
            })
            val = true
        }
    }).catch((error)=> {
        console.log(error)
    })

    return val
}

export const ObtenerEstadosPendientesReducer = ( value ) => async ( dispatch ) => {

    dispatch({
        type : LOADING_PENDING_STATUS,
        payload : true
    })
                
    await fetch(config.api_url+'status/show/pending-states',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                date_final : value ? value : moment()
            }),
        }
    )
    .then( async res => {return res.json()})
    .then(data => {

        dispatch({
            type: GET_PENDING_STATUS,
            payload : {
                status : data.datos,
                statusdistribuidoras : data.espsDistribuidoras
            }
        })

    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type : LOADING_PENDING_STATUS,
        payload : false
    })
}

export const ActualizarStatusHomologacionReducer = ( espid, date_final ) => async ( dispatch ) => {
    console.log(espid)

    await fetch(config.api_url+'status/edit/status-approval',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_espid : espid
            }),
        }
    )
    .then( async res => {return res.json()})
    .then(data => {

        if(data.response){
            notificacionServidor('success', data.message)
            dispatch(ObtenerEstadosPendientesReducer(date_final))
        }

    }).catch((error)=> {
        console.log(error)
    })
}
