import {
    OBTENER_DATA_HOMOLOGADOS,
    OBTENER_DATA_CANTIDAD_HOMOLOGADOS,
    CARGANDO_DATA_HOMOLOGADOS,
    FILTRO_INPUT_DATA_HOMOLOGADOS,
    FILTRO_ORDEN_DATA_HOMOLOGADOS,
    PAGINA_TABLA_DATA_HOMOLOGADOS,
    MOSTRAR_OTROS_HOMOLOGADOS,
    FILTRO_TIPOS_CONEXIONES_HOMOLOGADOS
} from '../../../../Constans/Homologaciones/Homologados/Homologados'

const INIT_STATE = {
    rex_data_homologados                : [],
    rex_data_cantidad_homologados       : null,
    rex_cargando_data_homologados       : true,
    rex_filtro_input_data_homologados   : {},
    rex_filtro_orden_data_homologados   : {column:null,orden:null},
    rex_pagina_tabla_data_homologados   : 1,
    rex_mostrar_otros_homologados       : false,
    rex_filtro_conexiones_homologados : []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FILTRO_TIPOS_CONEXIONES_HOMOLOGADOS: {
            return {
                ...state,
                rex_filtro_conexiones_homologados : action.payload
            }
        }
        case MOSTRAR_OTROS_HOMOLOGADOS: {
            return {
                ...state,
                rex_mostrar_otros_homologados : action.payload
            }
        }
        case PAGINA_TABLA_DATA_HOMOLOGADOS: {
            return {
                ...state,
                rex_pagina_tabla_data_homologados : action.payload
            }
        }
        case FILTRO_ORDEN_DATA_HOMOLOGADOS: {
            return {
                ...state,
                rex_filtro_orden_data_homologados : action.payload
            }
        }
        case FILTRO_INPUT_DATA_HOMOLOGADOS: {
            return {
                ...state,
                rex_filtro_input_data_homologados : action.payload
            }
        }
        case OBTENER_DATA_HOMOLOGADOS: {
            return {
                ...state,
                rex_data_homologados : action.payload
            }
        }
        case OBTENER_DATA_CANTIDAD_HOMOLOGADOS: {
            return {
                ...state,
                rex_data_cantidad_homologados : action.payload
            }
        }
        case CARGANDO_DATA_HOMOLOGADOS: {
            return {
                ...state,
                rex_cargando_data_homologados : action.payload
            }
        }
        default:
            return state;
    }
}
